import Utils from "../../Utils";
import toast, { Toaster } from "react-hot-toast";

export const sendMobileOtpSignup = (value, callback) => {
  return (dispatch) => {
    Utils.api.postApiCall(
      `${Utils.EndPoint.sendMobileOtpSignup}`,
      value,
      (responseData) => {
        dispatch({
          type: Utils.actionName.sendMobileOtpSignup,
          payload: { ...value, Response: responseData?.data },
        });
        if (responseData?.status == 200) {
          callback(responseData);
          toast.success(`${responseData?.data?.message}`, {
            position: "top-center",
          });
        } else {
          toast.error(`${responseData?.data?.message}`, {
            position: "top-center",
          });
        }
      },
      (error) => {
        toast.error(`${error?.payload?.message}`, {
          position: "top-center",
        });
      }
    );
  };
};

export const verifyMobileOtpSignup = (value, callback) => {
  return (dispatch) => {
    Utils.api.postApiCall(
      `${Utils.EndPoint.verifyMobileOtpSignup}`,
      value,
      (responseData) => {
        dispatch({
          type: Utils.actionName.verifyMobileOtpSignup,
          payload: { ...value, Response: responseData?.data },
        });
        if (responseData?.status == 200) {
          if (responseData?.data?.statusCode == 200) {
            toast.success(`${responseData?.data?.APICODERESULT}`, {
              position: "top-center",
            });
            callback(responseData);
          } else {
            toast.error(`${responseData?.data?.APICODERESULT}`, {
              position: "top-center",
            });
          }
        }
      },
      (error) => {
        toast.error(`${error?.payload?.APICODERESULT}`, {
          position: "top-center",
        });
      }
    );
  };
};

export const signup = (value, callback) => {
  return (dispatch) => {
    Utils.api.postApiCall(
      `${Utils.EndPoint.signup}`,
      value,
      (responseData) => {
        dispatch({
          type: Utils.actionName.signup,
          payload: { ...value, Response: responseData?.data },
        });
        if (responseData?.status == 200) {
          if (responseData?.data?.status) {
            toast.success(`${responseData?.data?.message}`, {
              position: "top-center",
            });
            callback(responseData);
          } else {
            toast.error(`${responseData?.data?.message}`, {
              position: "top-center",
            });
          }
        } else {
          toast.error(`${responseData?.data?.message}`, {
            position: "top-center",
          });
        }
      },
      (error) => {
        toast.error(`${error?.payload?.message}`, {
          position: "top-center",
        });
      }
    );
  };
};

export const socialSignIn = (value, callback) => {
  return (dispatch) => {
    Utils.api.postApiCall(
      `${Utils.EndPoint.socialSignIn}`,
      value,
      (responseData) => {
        dispatch({
          type: Utils.actionName.socialSignIn,
          payload: { ...value, Response: responseData?.data },
        });
        if (responseData?.status === 200) {
          if (responseData?.data?.status) {
            toast.success(`${responseData?.data?.message}`, {
              position: "top-center",
            });
            callback(responseData);
          } else {
            toast.error(`${responseData?.data?.message}`, {
              position: "top-center",
            });
          }
        } else {
          toast.error(`${responseData?.data?.message}`, {
            position: "top-center",
          });
        }
      },
      (error) => {
        toast.error(`${error?.payload?.message}`, {
          position: "top-center",
        });
      }
    );
  };
};

export const login = (value, callback) => {
  return (dispatch) => {
    Utils.api.loginApiCall(
      `${Utils.EndPoint.login}`,
      value,
      (responseData) => {
        dispatch({
          type: Utils.actionName.login,
          payload: { ...value, Response: responseData?.data },
        });
        if (responseData?.status == 200) {
          if (responseData?.data?.statusCode == 200) {
            toast.success(`${responseData?.data?.APICODERESULT}`, {
              position: "top-center",
            });
            localStorage.setItem(
              "accessToken",
              responseData?.data?.result?.token
            );
            localStorage.setItem(
              "user",
              JSON.stringify(responseData?.data?.result)
            );

            callback(responseData);
          } else {
            toast.error(`${responseData?.data?.APICODERESULT}`, {
              position: "top-center",
            });
          }
        }
      },
      (error) => {
        toast.error(`${error?.payload?.APICODERESULT}`, {
          position: "top-center",
        });
      }
    );
  };
};

export const sendOtpAuth = (payload, callback) => {
  return (dispatch) => {
    Utils.api.postApiCall(
      `${Utils.EndPoint.sendOtpAuth}`,
      payload,
      (responseData) => {
        console.log(responseData, "responsedara");
        dispatch({
          type: Utils.actionName.sendOtpAuth,
          payload: { ...payload, Response: responseData?.data },
        });
        if (responseData?.status === 200) {
          if (responseData?.data?.status) {
            toast.success(`OTP has been sent successfully!`, {
              position: "top-center",
            });
            callback(responseData);
          } else {
            toast.error(`${responseData?.data?.message}`, {
              position: "top-center",
            });
          }
        } else {
          toast.error(`Failed to send OTP. Please try again.`, {
            position: "top-center",
          });
        }
      },
      (error) => {
        toast.error(
          `${error?.payload?.message || "An unexpected error occurred."}`,
          {
            position: "top-center",
          }
        );
      }
    );
  };
};

export const verifyMobileOtpLogin = (value, callback) => {
  console.log(value, "value");
  return (dispatch) => {
    Utils.api.postApiCall(
      `${Utils.EndPoint.verifyMobileOtpLogin}`,
      value,
      (responseData) => {
        console.log(responseData?.data?.message, "OTPTOKEN");
        if (responseData?.status == 200) {
          if (responseData?.data?.status) {
            dispatch({
              type: Utils.actionName.verifyMobileOtpLogin,
              payload: { ...value, Response: responseData?.data },
            });
            localStorage.setItem("token", responseData?.data?.data?.token);
            toast.success(`${responseData?.data?.message}`, {
              position: "top-center",
            });
            callback(responseData);
          } else {
            toast.error(`${responseData?.data?.message}`, {
              position: "top-center",
            });
          }
        } else {
          toast.error(`${responseData?.data?.message}`, {
            position: "top-center",
          });
        }
      },
      (error) => {
        toast.error(`${error?.payload?.message}`, {
          position: "top-center",
        });
      }
    );
  };
};

export const sendOtpEmChanges = (value, callback) => {
  return (dispatch) => {
    Utils.api.postTokenApiCall(
      `${Utils.EndPoint.sendOtpEmChanges}`,
      value,
      (responseData) => {
        console.log(responseData, "OTPTOKEN");
        // Dispatch the action with the response
        dispatch({
          type: Utils.actionName.sendOtpEmChanges,
          payload: { ...value, Response: responseData?.data },
        });

        // Handle successful response
        if (responseData?.status) {
          callback(responseData);

          toast.success(responseData?.message, {
            position: "top-center",
          });
        }
      },
      // Handle API errors
      (error) => {
        console.log(error, "OTPTOKEN");
        // toast.error(error?.payload?.APICODERESULT || "Error occurred", {
        //   position: "top-center",
        // });
      }
    );
  };
};

export const verifyOtpEmChanges = (value, callback) => {
  return (dispatch) => {
    Utils.api.postTokenApiCall(
      `${Utils.EndPoint.verifyOtpEmChanges}`,
      value,
      (responseData) => {
        dispatch({
          type: Utils.actionName.verifyOtpEmChanges,
          payload: { ...value, Response: responseData?.data },
        });
        if (responseData?.status == 200) {
          if (responseData?.data?.statusCode == 200) {
            toast.success(`OTP verification successful!`, {
              position: "top-center",
            });
            callback(responseData);
          } else {
            toast.error(`${responseData?.data?.APICODERESULT}`, {
              position: "top-center",
            });
          }
        }
      },
      (error) => {
        toast.error(`${error?.payload?.APICODERESULT}`, {
          position: "top-center",
        });
      }
    );
  };
};

export const forgetPassword = (value, callback) => {
  return (dispatch) => {
    Utils.api.postApiCall(
      `${Utils.EndPoint.forgetPassword}`,
      value,
      (responseData) => {
        dispatch({
          type: Utils.actionName.forgetPassword,
          payload: { ...value, Response: responseData?.data },
        });
        if (responseData?.status == 200) {
          if (responseData?.data?.statusCode == 200) {
            toast.success(`${responseData?.data?.APICODERESULT}`, {
              position: "top-center",
            });
            callback(responseData);
          } else {
            toast.error(`${responseData?.data?.APICODERESULT}`, {
              position: "top-center",
            });
          }
        }
      },
      (error) => {
        toast.error(`${error?.payload?.APICODERESULT}`, {
          position: "top-center",
        });
      }
    );
  };
};

export const expertise = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.expertise,
      "",
      (resData) => {
        if (resData.status) {
          callback(resData?.data);
          dispatch({
            type: Utils.actionName.expertise,
            payload: {
              expertiseData: resData?.data,
            },
          });
          callback(resData?.data);
        } else {
        }
      },
      (error) => {}
    );
  };
};
export const topTutors = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.topTutors,
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.topTutors, // Make sure 'topTutors' is defined in Utils.actionName
            payload: {
              topTutorsData: resData,
            },
          });
          callback(resData?.data);
        } else {
          console.error("Failed to fetch Top Tutors");
        }
      },
      (error) => {
        console.error("Error fetching Top Tutors:", error);
      }
    );
  };
};

export const qualifications = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.qualifications,
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.qualifications,
            payload: {
              qualificationsData: resData,
            },
          });
          callback(resData?.data);
        } else {
        }
      },
      (error) => {}
    );
  };
};

export const grades = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.grades,
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.grades,
            payload: {
              gradesData: resData?.data,
            },
          });
          callback(resData?.data);
        } else {
        }
      },
      (error) => {}
    );
  };
};

export const subjects = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.subjects,
      "",
      (resData) => {
        console.log(resData?.data, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.subjects,
            payload: {
              subjectsData: resData?.data,
            },
          });
          callback(resData?.data);
        } else {
        }
      },
      (error) => {}
    );
  };
};
export const curriculums = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.curriculums,
      "",
      (resData) => {
        console.log(resData?.data, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.curriculums,
            payload: {
              curriculumsData: resData?.data,
            },
          });
          callback(resData?.data);
        } else {
        }
      },
      (error) => {}
    );
  };
};

export const sessions = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.sessions,
      "",
      (resData) => {
        console.log(resData?.data, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.sessions,
            payload: {
              sessionsData: resData?.data,
            },
          });
          callback(resData?.data);
        } else {
        }
      },
      (error) => {}
    );
  };
};

export const getProfile = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getProfile,
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getProfile,
            payload: {
              getProfileData: resData?.data,
            },
          });
          callback(resData?.data);
        } else {
        }
      },
      (error) => {}
    );
  };
};

export const getStudentDetails = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getStudentDetails, // Assuming a similar endpoint exists
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getStudentDetails, // Action name for student details
            payload: {
              studentDetailsData: resData?.data,
            },
          });
          callback(resData?.data); // Pass data to callback
        } else {
          console.error("Failed to fetch student details:", resData.message);
        }
      },
      (error) => {
        console.error("Error fetching student details:", error);
      }
    );
  };
};

export const getCurriculums = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getCurriculums, // Endpoint for curriculums
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getCurriculums, // Action name for curriculums
            payload: {
              curriculumsData: resData?.data,
            },
          });
          callback(resData?.data); // Pass data to callback
        } else {
          console.error("Failed to fetch curriculums:", resData.message);
        }
      },
      (error) => {
        console.error("Error fetching curriculums:", error);
      }
    );
  };
};

export const getGradeClass = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getGradeClass, // Endpoint for grade class
      "",
      (resData) => {
        console.log(resData, "resData123");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getGradeClass, // Action name for grade class
            payload: {
              gradeClassData: resData?.data,
            },
          });
          callback(resData?.data); // Pass data to callback
        } else {
          console.error("Failed to fetch grade class:", resData.message);
        }
      },
      (error) => {
        console.error("Error fetching grade class:", error);
      }
    );
  };
};

export const getTutorProfile = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getTutorProfile,
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getTutorProfile,
            payload: {
              getTutorProfileData: resData?.data,
            },
          });
          callback(resData?.data);
        } else {
        }
      },
      (error) => {}
    );
  };
};

export const getStudentProfile = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getStudentProfile,
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getStudentProfile,
            payload: {
              getStudentProfileData: resData?.data,
            },
          });
          callback(resData?.data);
        } else {
        }
      },
      (error) => {}
    );
  };
};

// export const updateStudentProfile = (data, callback) => {
//   return (dispatch) => {
//     Utils.api.putApiCall(
//       Utils.EndPoint.updateStudentProfile,
//       data,
//       (resData) => {
//         console.log(resData, "resData");
//         if (resData.status) {
//           dispatch({
//             type: Utils.actionName.updateStudentProfile,
//             payload: {
//               updateStudentProfileData: resData?.data,
//             },
//           });
//           callback(resData?.data);
//         } else {
//           // Handle error if needed
//           console.error("Failed to update student profile");
//         }
//       },
//       (error) => {
//         console.error("API call error:", error);
//       }
//     );
//   };
// };

export const updateStudentProfile = (data, callback) => {
  return (dispatch) => {
    Utils.api.putApiCall(
      Utils.EndPoint.updateStudentProfile,
      data,
      (resData) => {
        if (resData.status) {
          dispatch({
            type: Utils.actionName.updateStudentProfile,
            payload: {
              updateStudentProfileData: resData?.data,
            },
          });
          callback(resData?.data);
        } else {
        }
      },
      (error) => {}
    );
  };
};

export const updateAcadmicDetails = (data, callback) => {
  return (dispatch) => {
    Utils.api.putApiCall(
      Utils.EndPoint.updateAcadmicDetails,
      data,
      (resData) => {
        if (resData?.status) {
          dispatch({
            type: Utils.actionName.updateAcadmicDetails,
            payload: {
              updateAcadmicDetailsData: resData?.data,
            },
          });
          if (callback) callback(resData.data);
        } else {
          console.warn(
            "Update academic details failed:",
            resData?.message || "Unknown error"
          );
        }
      },
      (error) => {
        console.error(
          "Error in API call:",
          error?.response || error.message || "Unknown error"
        );
      }
    );
  };
};

export const updateTutorProfile = (data, callback, failedCallback) => {
  return (dispatch) => {
    Utils.api.putApiCall(
      Utils.EndPoint.updateTutorProfile,
      data,
      (resData) => {
        if (resData.status) {
          callback(resData);
          toast.success(`${resData?.message}`, {
            position: "top-center",
          });
          dispatch({
            type: Utils.actionName.updateTutorProfile,
            payload: {
              updateTutorProfileData: resData?.data,
            },
          });
        } else {
          failedCallback();
        }
      },
      (error) => {
        failedCallback();
      }
    );
    // func();
  };
};

export const updateTutorDocument = (data, func) => {
  return (dispatch) => {
    Utils.api.putApiCall(
      Utils.EndPoint.updateTutorDocument,
      data,
      (resData) => {
        if (resData.status) {
          // callback(resData?.data);
          dispatch({
            type: Utils.actionName.updateTutorDocument,
            payload: {
              updateTutorDocumentData: resData?.data,
            },
          });
          func();
        } else {
          func();
        }
        func();
        
      },
      (error) => {}
    );
  };
};
export const faqs = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.faqs,
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.faqs, // Make sure 'faqs' is defined in Utils.actionName
            payload: {
              faqsData: resData,
            },
          });
          callback(resData?.data);
        } else {
          console.error("Failed to fetch FAQs");
        }
      },
      (error) => {
        console.error("Error fetching FAQs:", error);
      }
    );
  };
};

export const getAcadmicTutorDetails = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getAcadmicTutorDetails,
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getAcadmicTutorDetails,
            payload: {
              getAcadmicTutorDetailsData: resData,
            },
          });
          callback(resData?.data);
        } else {
          console.error("Failed to fetch getAcadmicTutorDetails");
        }
      },
      (error) => {
        console.error("Error fetching getAcadmicTutorDetails:", error);
      }
    );
  };
};

export const getRecreationalTutorDetails = (
  expertise_id = 1,
  search = "",
  callback
) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getRecreationalTutorDetails,
      `?expertise_id=${expertise_id}&name=${search}`,
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getRecreationalTutorDetails, // Make sure 'faqs' is defined in Utils.actionName
            payload: {
              getRecreationalTutorDetailsData: resData,
            },
          });
          callback(resData?.data);
        } else {
          console.error("Failed to fetch getRecreationalTutorDetails");
        }
      },
      (error) => {
        console.error("Error fetching getRecreationalTutorDetails:", error);
      }
    );
  };
};

export const getCourseCategories = (type, callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getCourseCategories,
      `?type=${type}`,
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getCourseCategories,
            payload: {
              getCourseCategoriesData: resData,
            },
          });
          if (callback) callback(resData?.data);
        }
      },
      (error) => {
        console.error(error); // Handle error
      }
    );
  };
};

export const classNschedules = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.classNschedules,
      "",
      (resData) => {
        if (resData.status) {
          dispatch({
            type: Utils.actionName.classNschedules,
            payload: {
              classNschedulesData: resData,
            },
          });
          if (callback) callback(resData?.data);
        }
      },
      (error) => {
        console.error(error); // Handle error
      }
    );
  };
};
export const packageTypes = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.packageTypes,
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.packageTypes,
            payload: {
              packageTypesData: resData,
            },
          });
          if (callback) callback(resData?.data);
        }
      },
      (error) => {
        console.error(error); // Handle error
      }
    );
  };
};
export const getExpertise = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getExpertise,
      "",
      (resData) => {
        console.log(resData, "resData");
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getExpertise,
            payload: {
              getExpertiseData: resData,
            },
          });
          if (callback) callback(resData?.data);
        }
      },
      (error) => {
        console.error(error); // Handle error
      }
    );
  };
};

export const getCourseDetails = (course_category_id = 1, callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getCourseDetails,
      `?course_category_id=${course_category_id}`,
      (resData) => {
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getCourseDetails, // Make sure 'faqs' is defined in Utils.actionName
            payload: {
              getCourseDetailsData: resData,
            },
          });
          callback(resData?.data);
        } else {
          console.error("Failed to fetch getCourseDetails");
        }
      },
      (error) => {
        console.error("Error fetching getCourseDetails:", error);
      }
    );
  };
};

export const getCourseDescription = (id, callback) => {
  return (dispatch) => {
    if (!id) {
      console.error("ID is required for getCourseDescription");
      return;
    }

    Utils.api.getApiCall(
      Utils.EndPoint.getCourseDescription,
      `?id=${id}`,
      (resData) => {
        console.log("Response data:", resData);
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getCourseDescription,
            payload: {
              getCourseDescriptionData: resData,
            },
          });
          if (callback) callback(resData?.data);
        } else {
          console.error("Failed to fetch course description");
        }
      },
      (error) => {
        console.error("Error fetching getCourseDescription:", error);
      }
    );
  };
};

export const getTutorDescription = (_id, callback) => {
  return (dispatch) => {
    if (!_id) {
      console.error("ID is required for getTutorDescription");
      return;
    }

    Utils.api.getApiCall(
      Utils.EndPoint.getTutorDescription,
      `?id=${_id}`,
      (resData) => {
        console.log("Response data:", resData);
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getTutorDescription,
            payload: {
              getTutorDescriptionData: resData,
            },
          });
          if (callback) callback(resData?.data);
        } else {
          console.error("Failed to fetch course description");
        }
      },
      (error) => {
        console.error("Error fetching getTutorDescription:", error);
      }
    );
  };
};

export const getRecreationalDescription = (_id, callback) => {
  return (dispatch) => {
    if (!_id) {
      console.error("ID is required for getRecreationalDescription");
      return;
    }

    Utils.api.getApiCall(
      Utils.EndPoint.getRecreationalDescription,
      `?id=${_id}`,
      (resData) => {
        console.log("Response data:", resData);
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getRecreationalDescription,
            payload: {
              getRecreationalDescriptionData: resData,
            },
          });
          if (callback) callback(resData?.data);
        } else {
          console.error("Failed to fetch course description");
        }
      },
      (error) => {
        console.error("Error fetching getRecreationalDescription:", error);
      }
    );
  };
};

export const setFormData = (data) => ({
  type: Utils.actionName.SET_FORM_DATA,
  payload: data,
});
