import React from "react";
import { Grid, Box, Typography, Link } from "@mui/material";
import bgimg from "../../assets/download app/Banner.png";
import playstore from "../../assets/download app/Frame 418195.png";
import AppleStore from "../../assets/download app/Frame 418196.png";
import { useTranslation } from "react-i18next";

const AppDownload = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        background: {
          xs: "conic-gradient(from 188.1deg at 54.37% 60.18%, #E0E3E2 -47.45deg, #EBEBEB 223.33deg, #D5DBDC 278.67deg, #E0E3E2 312.55deg, #EBEBEB 583.33deg)", // Gradient for mobile
          md: "none", // Remove gradient for larger screens
        },
        backgroundImage: {
          xs: "none", // No image for mobile
          md: `url(${bgimg})`, // Background image for larger screens
        },
        backgroundSize: "cover",
        backgroundPosition: "center",
        p: { xs: 3, md: 4 },
        height: { xs: "auto", md: "500px" },
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        {/* Left Side - Text Section (70%) */}
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              textAlign: { xs: "center", md: "left" },
              ml: { xs: 0, md: 6 },
            }}
          >
            {/* 1st Text */}
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: { xs: "22px", md: "32px" },
                lineHeight: 1.2,
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              {t("Unlock a world of learning with")} <br />{" "}
              {t("the Taleem app")}
            </Typography>

            {/* 2nd Text */}
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: 400,
                fontSize: { xs: "14px", md: "20px" },
                color: "#737373",
                mt: 1,
                lineHeight: 1.6,
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              {t("Download now and take the next step in your educational")}{" "}
              <br />
              {t("journey with Taleem!")}
            </Typography>

            {/* 3rd Text */}
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{
                mt: 3,
                fontWeight: 520,
                fontSize: { xs: "16px", md: "24px" },
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              {t("Download App From")}
            </Typography>

            {/* Store Links */}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: { xs: "center", md: "flex-start" },
                mt: 2,
              }}
            >
              <Link href="#" target="_blank">
                <Box
                  component="img"
                  src={playstore}
                  alt="Play Store"
                  sx={{
                    maxWidth: { xs: "120px", md: "200px" },
                    height: "auto",
                    borderRadius: "16px",
                  }}
                />
              </Link>
              <Link href="#" target="_blank">
                <Box
                  component="img"
                  src={AppleStore}
                  alt="Apple Store"
                  sx={{
                    maxWidth: { xs: "120px", md: "200px" },
                    height: "auto",
                    borderRadius: "16px",
                  }}
                />
              </Link>
            </Box>
          </Box>
        </Grid>

        {/* Right Side - Empty (30%) */}
        <Grid item xs={12} md={5} />
      </Grid>
    </Box>
  );
};

export default AppDownload;
