const EndPoint = {
  sendMobileOtpSignup: "send-otp",
  verifyMobileOtpSignup: "verifyMobileOtpSignup",
  signup: "sign-up",
  login: "emailLogin",
  sendMobileOtpLogin: "sendMobileOtpLogin",
  verifyMobileOtpLogin: "verify-otp",
  forgetPassword: "forgetPassword",
  getCategoryWithProduct: "getCategoryWithProduct",
  Home: "Home",
  getProduct: "getProduct",
  topTutors:"top-tutors",
socialSignIn:  "social-signin",
sendOtpEmChanges: "send-otp-auth",
verifyOtpEmChanges: "verify-otp-auth",
  uploadDoc:"upload-file/document",

  //list from admin
  expertise: "expertise",
  qualifications: "qualifications",
  grades: "grades",
  curriculums: "curriculums",
  sessions: "sessions",
  subjects: "subjects",
  packageTypes:"package-types",
  classNschedules:"class-and-schedules",

  //Profile api's
  getProfile: "profile-details",
  getTutorProfile: "tutor/tutor-profile",
  getStudentProfile: "student/student-detail",
  updateStudentProfile: "student/student-profile",
  updateTutorProfile: "tutor/update-tutor-profile",
  updateTutorDocument: "tutor/documents",
  updateAcadmicDetails: "student/student-acadmic",

  //tutor acadmic or recreational data
  profileStatus:"/tutor/profile-status",
  getAcadmicTutorDetails: "student/tutors-academic",
  getRecreationalTutorDetails: "student/tutors-recreational",
  getCourseDetails: "student/tutors-all-courses",
  getCourseCategories: "course-categories",
  getCourseDescription: "student/tutor-detail-courses",
  getTutorDescription: "student/tutor-detail-academic",
  getRecreationalDescription: "student/tutor-detail-recreational",
  getExpertise:"expertise",
  
  //Rate card api's
  getRateCard: "tutor/academic-rate-card",
  addRateCard: "tutor/academic-rate-card",

  //landing page
  faqs: "faqs",

  //schedules api's
  getSchedules: "tutor/schedules",
  schedules: "tutor/schedules",
  //homepage student
  getStudentDetails: "student/student-detail",
  getGradeClass: "grade-class",
  getCurriculums: "curriculums",

  //policies links
  getContent: "static-contents",
getAvalability:"student/tutor-schedules",
getClasstype:"/student/tutor-card-details/academic",

};
export default EndPoint;
