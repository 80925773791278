import React, { useState } from "react";
import { Button, IconButton, Menu, MenuItem, useMediaQuery } from "@mui/material";
import { Menu as MenuIcon, KeyboardArrowDown } from "@mui/icons-material";
import navbarlogopromo from "../../assets/navbar/nav logo.png";

const NavbarPromo = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "74px",
        borderRadius: "20px",
        background: "linear-gradient(90deg, rgba(255, 255, 255, 0.35) 0%, rgba(153, 153, 153, 0.25) 100%)",
        backdropFilter: "blur(4px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 20px",
        boxSizing: "border-box",
      }}
    >
      {/* Mobile View: Toggle Menu */}
      {isMobile ? (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "10px", flex: 1 }}>
            <IconButton
              style={{
                color: "#FFF",
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={navbarlogopromo}
              alt="Navbar Logo"
              style={{ height: "50px", margin: "0 auto" }}
            />
          </div>

          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                width: "200px",
                padding: "10px 0",
                background: "rgba(255, 255, 255, 0.95)",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            {["Home", "Prices", "Contact Us", "Language", "Join as a Tutor", "Book a Tutor"].map(
              (item, index) => (
                <MenuItem
                  key={index}
                  onClick={handleMenuClose}
                  style={{
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#000",
                    padding: "12px 16px",
                  }}
                >
                  {item}
                </MenuItem>
              )
            )}
          </Menu>
        </>
      ) : (
        <>
          {/* Desktop View */}
          <img
            src={navbarlogopromo}
            alt="Navbar Logo"
            style={{ height: "50px" }}
          />

          {/* Links */}
          <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
            <span style={{ fontWeight: "700", fontSize: "16px", color: "#000" }}>
              Home
            </span>
            <span
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#FFF",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Prices <KeyboardArrowDown style={{ marginLeft: "2px" }} />
            </span>

            <span style={{ fontWeight: "400", fontSize: "16px", color: "#FFF" }}>
              Contact Us
            </span>

            <span
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#FFF",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              Language <KeyboardArrowDown style={{ marginLeft: "2px" }} />
            </span>
          </div>

          {/* Buttons */}
          <div style={{ display: "flex", gap: "16px" }}>
            <Button
              variant="outlined"
              sx={{
                width: "160px",
                height: "44px",
                padding: "12px 16px",
                borderRadius: "8px",
                borderColor: "#FFFFFF",
                textTransform: "none",
                fontWeight: 500,
                fontSize: "15px",
                fontFamily: "'Poppins', sans-serif",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              Join as a Tutor
            </Button>

            <Button
              variant="contained"
              sx={{
                width: "140px",
                height: "44px",
                padding: "12px 16px",
                borderRadius: "8px",
                backgroundColor: "#40A39B",
                textTransform: "none",
                fontWeight: 500,
                fontSize: "15px",
                fontFamily: "'Poppins', sans-serif",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#37a393",
                },
              }}
            >
              Book a Tutor
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default NavbarPromo;
