import React, { useEffect } from 'react';
import { Container, Typography, Link, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../Redux/Actions';

const LoggedInFooter = () => {
  const dispatch = useDispatch();

  // Fetch profile data on mount
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const { user_type } = useSelector((state) => state.getProfileReducer?.getProfileData || {});

  const isStudent = user_type === "3"; // Assuming '3' represents student type

  return (
    <footer className="bg-[#40A39B] w-full h-auto py-2">
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { sm: 'space-between' },
          alignItems: 'center',
          height: '100%',
          textAlign: { xs: 'center', sm: 'left' },
          gap: { xs: 1, sm: 0 },
        }}
      >
        <Typography
          className="text-black"
          sx={{ fontWeight: 550, fontSize: { xs: '14px', sm: '15px' } }}
        >
          Copyright © 2024 Taleem. All rights reserved
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            gap: { xs: 1, sm: 2 },
          }}
        >
          <Link
            href="#"
            underline="none"
            className="text-black"
            sx={{ fontWeight: 500, fontSize: { xs: '14px', sm: '16px' } }}
          >
            {isStudent ? "Continue as a Student" : "Continue as a Tutor"}
          </Link>
          <Box
            component="span"
            className="text-black"
            sx={{
              display: { xs: 'none', sm: 'inline' },
              fontWeight: 500,
              fontSize: '16px',
            }}
          >
            |
          </Box>
          <Link
            href="#"
            underline="none"
            className="text-black"
            sx={{ fontWeight: 500, fontSize: { xs: '14px', sm: '16px' } }}
          >
            Continue as a Parent
          </Link>
        </Box>
      </Container>
    </footer>
  );
};

export default LoggedInFooter;
