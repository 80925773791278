import React from "react";
// import FooterCss from "footer.css";
import "./footer.css";
import taleemLogo from "../../assets/footer/Taleem Logo.png";
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";
import linkedin from "../../assets/linkedin.png";
import twitter from "../../assets/twitter.png";
import youtube from "../../assets/youtube.png";
import mail from "../../assets/mail.png";
import phone from "../../assets/phone.png";
import googlePlay from "../../assets/google-btn.png";
import Ios from "../../assets/ios-btn.png";
const FooterPromo = () => {
  return (
    <section className="footer">
      <div className="footer-wrapper">
        <div className="row">
          <div className="col-md-3 col-sm-12 col-12">
            <div className="footer-lft-side">
              <div className="footer-logo">
                <img src={taleemLogo} alt="" />
              </div>
              <div className="footer-abt">
                <div className="footer-heading">About Us</div>
                <p>
                  Welcome to Taleem, where education meets innovation. At
                  Taleem, we believe in the power of learning to transform lives
                </p>
              </div>
              <div className="social-links">
                <div className="footer-heading">Follow Us</div>
                <div className="linksbcx">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <img src={facebook} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src={instagram} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src={linkedin} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src={twitter} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <img src={youtube} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 col-sm-12 col-12">
            <div className="footer-rgt-side">
              <div className="subscribe-newsletter-bcx">
                <h5>Request a newsletter</h5>
                <div className="newsletterbx">
                  <input type="text" placeholder="Enter your email" />
                  <div className="footbtn">
                    <button type="button">Submit</button>
                  </div>
                </div>
              </div>
              <div className="footerinfo-bcx">
                <div className="row">
                  <div className="col-md-4 col-6">
                    <div className="footer-heading">Quick Links</div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">Home</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Plans & Pricing</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">FAQs</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="footer-heading">Legal</div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">Terms & Conditions</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="footer-heading">Get in Touch</div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">
                          <img src={mail} alt="" /> info@taleem.qa
                        </a>
                      </li>
                      {/* <li>
                        <a href="javascript:void(0)">
                          <img src={phone} alt="" /> 514-585-LOCK(5625)
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerbtmtxt">
            <p>Copyright© 2024 Taleem. All Right Reserved</p>
            <div className="downlodbtn">
                <a href="javascript:void(0)">
                    <img src={googlePlay} alt="" />
                </a>
                <a href="javascript:void(0)">
                    <img src={Ios} alt="" />
                </a>
            </div>
        </div>
      </div>
    </section>
  );
};

export default FooterPromo;