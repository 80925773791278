import React from "react";
import { Box, Typography, Button } from "@mui/material";

const PromoPackagesCards = () => {
  const cards = [
    {
      title: "Starter",
      description: "8 hr package with up to 50 courses",
      price: "300 QAR/ <span style='font-weight: 400; font-size: 18.81px;'>month</span>",
      button: {
        label: "Get Started",
        style: { border: "1.25px solid #0B2110", background: "#F9F9F9" },
      },
    },
    {
      title: "Pro",
      description: "16 hr package with up to 100 courses",
      price: "500 QAR/ <span style='font-weight: 400; font-size: 18.81px;'>month</span>",
      button: {
        label: "Get Started",
        style: {
          border: "1.25px solid #0B2110",
          background: "#40A39B",
          color: "#FFFFFF",
        },
      },
      recommended: true,
    },
    {
      title: "Premium",
      description: "24 hr package with up to 150 courses",
      price: "700 QAR/ <span style='font-weight: 400; font-size: 18.81px;'>month</span>",
      button: {
        label: "Get Started",
        style: { border: "1.25px solid #0B2110", background: "#F9F9F9" },
      },
    },
  ];

  const cardStyles = {
    container: {
      display: "flex",
      gap: "20px",
      justifyContent: "center",
      padding: "20px",
      background: "#ffffff",
      marginTop: "25px",
      flexWrap: "wrap",
    },
    card: (isRecommended) => ({
      width: "355.56px", // Fixed width across all screens
      height: "482.87px",
      padding: "52.68px 20.07px",
      borderRadius: "5.02px",
      boxShadow: "0px 0px 5.02px 0px #183B560D",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: isRecommended ? "rgba(52, 156, 75, 0.05)" : "#F9F9F9",
      position: "relative",
      marginBottom: "25px", // Consistent margin for spacing
    }),
    recommendedTag: {
      position: "absolute",
      top: "-32px",
      width: "100%",
      height: "40.13px",
      borderRadius: "5.02px 5.02px 0 0",
      background: "rgba(64, 163, 155, 1)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#FFFFFF",
      fontWeight: 600,
      fontSize: "18px",
    },
    button: {
      width: "148.64px",
      height: "39.07px",
      padding: "10px 15px",
      borderRadius: "10.03px",
      textTransform: "none",
      color: "#0B2110",
      fontWeight: 500,
      fontSize: "18.81px",
      lineHeight: "1",
    },
  };

  return (
    <Box sx={cardStyles.container}>
      {cards.map((card, index) => (
        <Box
          key={index}
          sx={cardStyles.card(card.recommended)}
        >
          {card.recommended && (
            <Box sx={cardStyles.recommendedTag}>Recommended</Box>
          )}

          {/* Title and Description Section */}
          <Box sx={{ textAlign: "center", gap: "10px", display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontWeight: 500, fontSize: "35.12px" }}>
              {card.title}
            </Typography>
            <Typography sx={{ fontWeight: 400, fontSize: "20.07px" }}>
              {card.description}
            </Typography>
          </Box>

          {/* Price Section */}
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "37.63px",
              lineHeight: "1.2",
            }}
            dangerouslySetInnerHTML={{ __html: card.price }}
          />

          {/* Button Section */}
          <Button
            variant="outlined"
            sx={{ ...cardStyles.button, ...card.button.style }}
          >
            {card.button.label}
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default PromoPackagesCards;
