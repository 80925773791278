import Utils from ".";
import toast, { Toaster } from "react-hot-toast";

const headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
  // 'Content-Type': 'application/json',
};
const lang = localStorage.getItem("lang");
const checkUserValidation = (data) => {
  if (data) {
    const { statusCode } = data;
    const { sessionExpired, unauthorized, accessDenied } =
      Utils.constants.apiErrorCode;

    if (statusCode) {
      return (
        statusCode === sessionExpired ||
        statusCode === unauthorized ||
        statusCode === accessDenied
      );
    }
    return false;
  }
  return false;
};

const logOutApiCall = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  sessionStorage.clear();
  window.location.reload();
};

const loginApiCall = (endPoint, params, successCallback, errorCallback) => {
  Utils.constants.axios
    .post(endPoint, params)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          statusCode: 408,
          message: "Something went wrong!",
        };
        errorCallback(payload);
      } else if (error.response) {
        let data = error.response.data;
        if (data?.code == 401) {
          logOutApiCall();
        }
        if (checkUserValidation(data)) {
          Utils.showAlert(2, data.message || "");
          setTimeout(() => {
            logOutApiCall();
          }, 1000);
        } else {
          errorCallback(error.response);
        }
      }
      // else if (!error.response) {
      //   let payload = {
      //     statusCode: -1,
      //     message: 'Please try again later',
      //   };
      //   errorCallback(payload);
      // }
    });
};

const deleteApiCall = async (
  endPoint,
  params = {},
  successCallback,
  errorCallback
) => {
  try {
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(params),
      redirect: "follow",
    };

    const response = await fetch(
      Utils.constants.API_URL + endPoint,
      requestOptions
    );

    // Handle network errors
    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.code === 401) {
        // logOutApiCall();
      } else if (response.status === 409) {
        console.log(response, errorData, "LALALA");
        errorCallback({ statusCode: 409, message: errorData?.message });
      } else {
        errorCallback({
          statusCode: response.status,
          message: errorData.message || "Request failed",
        });
      }
      return;
    }

    // Parse and handle successful response
    const result = await response.json();
    successCallback(result);
  } catch (error) {
    console.error("Fetch error:", error);
    // errorCallback({
    //   statusCode: -1,
    //   message: 'Please try again later',
    // });
  }
};

const postApiCall = (
  endPoint,
  params,
  successCallback,
  errorCallback,
  headerType
) => {
  if (headerType === "multi") {
    headers["Content-Type"] = "multipart/form-data";
  } else {
    headers["Content-Type"] = "application/json";
  }
  Utils.constants.axios
    .post(endPoint, params, { headers: headers })
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      console.log(error, "err----------->");
      if (error.code === "ECONNABORTED") {
        let payload = {
          statusCode: 408,
        };
        errorCallback(payload);
      } else if (error.response) {
        let data = error.response.data;
        if (data.code === 401) {
          logOutApiCall();
        }
        if (checkUserValidation(data)) {
          Utils.showAlert(2, data.message || "");
          setTimeout(() => {
            logOutApiCall();
          }, 1000);
        } else {
          let payload = {
            statusCode: error.response.status,
            payload: data,
            message: data.error,
          };
          errorCallback(payload);
        }
      }
      // else if (!error.response) {
      //   let payload= {
      //     statusCode: -1,
      //     payload:{},
      //     message: 'Please try again later',
      //   };
      //   errorCallback(payload);
      // }
    });
};
const postTokenApiCall = async (
  endPoint,
  body = {},
  successCallback,
  errorCallback,
  isArrayBuffer = false
) => {
  try {
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        lang: `${lang}`,
      },
      body: JSON.stringify(body),
      redirect: "follow",
    };

    const response = await fetch(
      Utils.constants.API_URL + endPoint,
      requestOptions
    );

    // Handle network errors
    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.code === 401) {
        logOutApiCall();
      } else {
        errorCallback({
          statusCode: response.status,
          message: errorData.message || "Request failed",
        });
      }
      return;
    }

    // Parse and handle successful response
    const result = await response.json();
    successCallback(result);
  } catch (error) {
    console.error("Fetch error:", error);
    errorCallback({
      statusCode: -1,
      message: "Please try again later",
    });
  }
};

const postMultiApiCall = async (
  endPoint,
  body, // Should be a FormData object
  successCallback,
  errorCallback
) => {
  try {
    const token = localStorage.getItem("token");
    
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        lang: `${lang}`,  // Keep 'lang' if required, remove Content-Type for FormData
      },
      body: body,  // This is the FormData object
      redirect: "follow",
    };

    const response = await fetch(Utils.constants.API_URL + endPoint, requestOptions);

    if (!response.ok) {
      // Handle server errors
      const errorData = await response.json();
      if (response.status === 401) {
        logOutApiCall();
      } else {
        errorCallback({
          statusCode: response.status,
          message: errorData.message || "Request failed",
        });
      }
      return;
    }

    const result = await response.json();  // Parse response
    successCallback(result);  // Invoke success callback with result
  } catch (error) {
    console.error("Fetch error:", error);
    errorCallback({
      statusCode: -1,
      message: "Please try again later",
    });
  }
};

// const postMultiApiCall = async (
//   endPoint,
//   body = {},
//   successCallback,
//   errorCallback,
//   isArrayBuffer = false
// ) => {
//   try {
//     const token = localStorage.getItem("token");
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "multipart/form-data",
//         lang: `${lang}`,
//       },
//       body: body,
//       redirect: "follow",
//     };

//     const response = await fetch(
//       Utils.constants.API_URL + endPoint,
//       requestOptions
//     );

//     // Handle network errors
//     if (!response.ok) {
//       const errorData = await response.json();
//       if (errorData.code === 401) {
//         logOutApiCall();
//       } else {
//         errorCallback({
//           statusCode: response.status,
//           message: errorData.message || "Request failed",
//         });
//       }
//       return;
//     }

//     // Parse and handle successful response
//     const result = await response.json();
//     successCallback(result);
//   } catch (error) {
//     console.error("Fetch error:", error);
//     errorCallback({
//       statusCode: -1,
//       message: "Please try again later",
//     });
//   }
// };
const getApiCall = (
  endPoint,
  params = "",
  successCallback,
  errorCallback,
  isArrayBuffer = false
) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Bearer ${token}`);

  myHeaders.append("lang", `${lang}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(Utils.constants.API_URL + endPoint + params, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json(); // Parse JSON response here
    })
    .then((result) => {
      // console.log(result); // Log the parsed result
      successCallback(result); // Call the success callback with the result
    })
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          statusCode: 408,
        };
        errorCallback(payload);
      } else if (error.response) {
        let data = error.response.data;
        if (data.code === 401) {
          logOutApiCall();
        }
        if (checkUserValidation(data)) {
          // If user session expired
          Utils.showAlert(2, data.message || "");
          setTimeout(() => {
            logOutApiCall();
          }, 1000);
        } else {
          errorCallback(error.response);
        }
      } else if (!error.response) {
        let payload = {
          statusCode: -1,
          message: "Please try again later",
        };
        errorCallback(payload);
      }
    });
};

// Utils.constants.axios
//   .get(Utils.constants.API_URL + endPoint + params, { headers: {
//     'Authorization': `Bearer ${localStorage.getItem('token')}`,
//     'Content-Type': 'application/json',
//   }
//     , responseType: isArrayBuffer?"arraybuffer":"json"
//   })
//   .then((response) => {
//     successCallback(response);
//   })
//   .catch((error) => {
//     if (error.code === 'ECONNABORTED') {
//       let payload = {
//         statusCode: 408,
//       };
//       errorCallback(payload);
//     } else if (error.response) {
//       let data = error.response.data;
//       if (data.code === 401) {
//         logOutApiCall();
//       }
//       if (checkUserValidation(data)) {
//         // If user session expired
//         Utils.showAlert(2, data.message || '');
//         setTimeout(() => {
//           logOutApiCall();
//         }, 1000);
//       } else {
//         errorCallback(error.response);
//       }
//     } else if (!error.response) {
//       let payload = {
//         statusCode: -1,
//         message: 'Please try again later',
//       };
//       errorCallback(payload);
//     }
//   });
// };

// const putApiCall = (
//   endPoint,
//   params,
//   successCallback,
//   errorCallback,
//   headerType
// ) => {
//   if (headerType === 'multi') {
//     headers['Content-Type'] = 'multipart/form-data';
//   }
//   Utils.constants.axios
//     .put(endPoint, params, { headers: headers })
//     .then((response) => {
//       successCallback(response);
//     })
//     .catch((error) => {
//       if (error.code === 'ECONNABORTED') {
//         let payload = {
//           statusCode: 408,
//         };
//         errorCallback(payload);
//       } else if (error.response) {
//         let data = error.response.data;
//         if (checkUserValidation(data)) {
//           Utils.showAlert(2, data.message || '');
//           setTimeout(() => {
//             logOutApiCall();
//           }, 1000);
//         } else {
//           errorCallback(error.response);
//         }
//       } else if (!error.response) {
//         let payload = {
//           statusCode: -1,
//           message: 'Please try again later',
//         };
//         errorCallback(payload);
//       }
//     });
// };

const putMultiApiCall = (
  endPoint,
  body = {},
  successCallback,
  errorCallback,
  isArrayBuffer = false
) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "multipart/form-data");
  myHeaders.append("lang", `${lang}`);
  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: body, // Stringify the body for PUT request
    redirect: "follow",
  };

  fetch(Utils.constants.API_URL + endPoint, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((result) => {
      successCallback(result);
    })
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          statusCode: 408,
        };
        errorCallback(payload);
      } else if (error.response) {
        let data = error.response.data;
        if (data.code === 401) {
          logOutApiCall();
        }
        if (checkUserValidation(data)) {
          Utils.showAlert(2, data.message || "");
          setTimeout(() => {
            logOutApiCall();
          }, 1000);
        } else {
          errorCallback(error.response);
        }
      } else {
        let payload = {
          statusCode: -1,
          message: "Please try again later",
        };
        errorCallback(payload);
      }
    });
};

const putApiCall = (
  endPoint,
  body = {},
  successCallback,
  errorCallback,
  isArrayBuffer = false
) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem("token");
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("lang", `${lang}`);
  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: JSON.stringify(body), // Stringify the body for PUT request
    redirect: "follow",
  };

  fetch(Utils.constants.API_URL + endPoint, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((result) => {
      successCallback(result);
    })
    .catch((error) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          statusCode: 408,
        };
        errorCallback(payload);
      } else if (error.response) {
        let data = error.response.data;
        if (data.code === 401) {
          logOutApiCall();
        }
        if (checkUserValidation(data)) {
          Utils.showAlert(2, data.message || "");
          setTimeout(() => {
            logOutApiCall();
          }, 1000);
        } else {
          errorCallback(error.response);
        }
      } else {
        let payload = {
          statusCode: -1,
          message: "Please try again later",
        };
        errorCallback(payload);
      }
    });
};

const api = {
  postApiCall,
  loginApiCall,
  putApiCall,
  putMultiApiCall,
  getApiCall,
  deleteApiCall,
  logOutApiCall,
  postTokenApiCall,
  postMultiApiCall
};

export default api;
