import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import PrivateRoute from "./privateRoute";
import ROUTES from "./routes";
import Layout from "../layouts";

function RoutesWrapper() {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress size={50} thickness={4} />
        </Box>
      }
    >
      <Routes>
        {ROUTES.map(({ path, id, Component, isPrivate }) => (
          <Route
            key={id}
            path={path}
            element={
              <Layout>
                {isPrivate ? (
                  <PrivateRoute>
                    <Component />
                  </PrivateRoute>
                ) : (
                  <Component />
                )}
              </Layout>
            }
          />
        ))}
      </Routes>
    </Suspense>
  );
}

export default RoutesWrapper;
