import React from 'react';
import { Typography, TextField, Button, IconButton, useMediaQuery, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import Check icon

const AddAccountModal = ({ onClose }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:960px)');

  // Handle closing the modal by clicking outside the content
  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-background') {
      onClose();
    }
  };

  return (
    <div
      id="modal-background"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
      }}
      onClick={handleOutsideClick}
    >
      <div
        style={{
          width: isMobile ? '90%' : isTablet ? '70%' : '500px',
          padding: isMobile ? '15px' : '20px',
          backgroundColor: '#ffffff',
          borderRadius: '28px',
          position: 'relative',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Heading with Checkmark */}
        <Box display="flex" alignItems="center" marginBottom="20px">
          <CheckCircleIcon
            sx={{
              color: '#40A39B',
              fontSize: isMobile ? '20px' : '24px',
              marginRight: '8px', // Space between icon and text
            }}
          />
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: isMobile ? '18px' : '24px',
              color: '#333',
            }}
          >
            Add Account
          </Typography>
        </Box>

        {/* Full Name Input */}
        <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: '8px', fontSize: '18px' }}>
          Full Name
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          defaultValue="Naseem Chikitani"
          sx={{
            marginBottom: '40px',
            '& .MuiInputBase-root': { fontSize: isMobile ? '14px' : '16px' },
          }}
        />

        {/* Save Profile Button */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#40A39B',
              color: '#fff',
              fontWeight: 500,
              fontSize: isMobile ? '14px' : '16px',
              borderRadius: '12px',
              width: isMobile ? '100%' : '315px',
              height: '48px',
              '&:hover': {
                backgroundColor: '#368e82',
              },
            }}
          >
            Save Profile
          </Button>
        </Box>

        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            backgroundColor: '#E0E0E0',
            color: '#fff',
            borderRadius: '50%',
            width: '36px',
            height: '36px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default AddAccountModal;
