import { combineReducers } from "redux";
import {
  signupReducer,
  verifyMobileOtpSignupReducer,
  loginReducer,
  sendMobileOtpSignupReducer,
  sendMobileOtpLoginReducer,
  verifyMobileOtpLoginReducer,
  expertiseReducer,
  curriculumsReducer,
  gradesReducer,
  qualificationsReducer,
  getProfileReducer,
  getTutorProfileReducer,
  getStudentProfileReducer,
  getRateCardReducer,
  sessionsReducer,
  faqsReducer,
  subjectsReducer,
  getSchedulesReducer,
  getGradeClassReducer,
  getCurriculumsReducer,
  getStudentDetailsReducer,
  getRecreationalTutorDetailsReducer,
  getAcadmicTutorDetailsReducer,
  getCourseDetailsReducer,
  getCourseCategoriesReducer,
  getCourseDescriptionReducer,
  getTutorDescriptionReducer,
  getRecreationalDescriptionReducer,
  getContentReducer,
  getExpertiseReducer,
  profileStatusReducer,
  topTutorsReducer,
  uploadDocReducer,
  packageTypesReducer,
  classNschedulesReducer,
  getAvalabilityReducer,
  getClasstypeReducer
} from "./Reducers";
import loginFormReducer from "./Reducers/signupFormReducer";
import {
  HomeReducer,
  getCategoryWithProductReducer,
  productReducer,
} from "./Reducers/HomeReducers";

let reducers = {
  verifyMobileOtpSignupReducer,
  signupReducer,
  loginReducer,
  sendMobileOtpSignupReducer,
  loginFormReducer,
  sendMobileOtpLoginReducer,
  verifyMobileOtpLoginReducer,
  getCategoryWithProductReducer,
  HomeReducer,
  productReducer,
  expertiseReducer,
  curriculumsReducer,
  qualificationsReducer,
  getProfileReducer,
  gradesReducer,
  getTutorProfileReducer,
  getStudentProfileReducer,
  getRateCardReducer,
  faqsReducer,
  subjectsReducer,
  sessionsReducer,
  getSchedulesReducer,
  getGradeClassReducer,
  getCurriculumsReducer,
  getStudentDetailsReducer,
  getRecreationalTutorDetailsReducer,
  getAcadmicTutorDetailsReducer,
  sessionsReducer,
  getCourseDetailsReducer,
  getCourseCategoriesReducer,
  getCourseDescriptionReducer,
  getTutorDescriptionReducer,
  getRecreationalDescriptionReducer,
  getContentReducer,
  getExpertiseReducer,
  profileStatusReducer,
  topTutorsReducer,
  uploadDocReducer,
  packageTypesReducer,
  classNschedulesReducer,
  getClasstypeReducer,
  getAvalabilityReducer,
};

const rootReducer = combineReducers({
  ...reducers,
  // demoData: demoReducer,
});
export default rootReducer;
