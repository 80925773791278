import React, { useEffect, useMemo } from 'react';
import { Box, Typography, Divider, Card, Button, Avatar, Container } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarBorderIcon from '@mui/icons-material/Star';
import girl from '../../assets/course card/girl.png';
import pic1 from "../../assets/schedule classes/Ellipse 36 (1).svg";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRecreationalDescription, getTutorDescription } from '../../Redux/Actions';

const TutorDetails = () => {
  const dispatch = useDispatch();
  const { _id } = useParams();  
  const navigate = useNavigate();

  useEffect(() => {
    if (_id) {
      dispatch(getTutorDescription(_id));
      dispatch(getRecreationalDescription(_id));
    }
  }, [_id, dispatch]);

  const { getTutorDescriptionData, getRecreationalDescriptionData } = useSelector((state) => ({
    getTutorDescriptionData: state.getTutorDescriptionReducer?.getTutorDescriptionData?.data?.profileData,
    getRecreationalDescriptionData: state.getRecreationalDescriptionReducer?.getRecreationalDescriptionData?.data?.profileData,
  }));

  const combinedData = useMemo(() => ({
    ...getTutorDescriptionData,
    recreationalInfo: getRecreationalDescriptionData,
  }), [getTutorDescriptionData, getRecreationalDescriptionData]);


  return (
    <Container maxWidth="md" sx={{ padding: { xs: 2, sm: 4 }, marginTop: 0}}>
      
   
      {/* Tutor Card */}
      <Card sx={{ width: '100%', borderRadius: '12px', background: 'linear-gradient(105.04deg, #C6FFC9 -25.33%, #D4EBFF 100%)', p: 3, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2, alignItems: 'center', mb: 3 }}>
        <Box 
          component="img" 
          src={
            combinedData?.image 
              ? `${process.env.REACT_APP_BASE_URL}${combinedData.image}` 
              : girl // Fallback to `girl` if `combinedData.image` is unavailable
          }
          alt="Tutor" 
          sx={{ 
            width: { xs: '80px', md: '100px' }, 
            height: { xs: '80px', md: '100px' }, 
            borderRadius: '8px', 
          }} 
        />
        <Box sx={{ flexGrow: 1, textAlign: { xs: 'center', md: 'left' } }}>
          <Typography variant="h6" sx={{ fontWeight: 600 ,   fontFamily:"'Poppins', sans-serif",}}>{combinedData?.name}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
            <BusinessCenterIcon sx={{ color: '#F3C623' }} />
            <Typography variant="body2" sx={{ color: '#333',   fontFamily:"'Poppins', sans-serif", }}>Music Tutors in University</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, flexWrap: 'wrap' }}>
            <LocationOnIcon sx={{ color: '#F3C623' }} />
            <Typography variant="body2" sx={{ color: '#333' ,   fontFamily:"'Poppins', sans-serif",}}>{combinedData?.address}</Typography>
            <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#E6E6E6' }} />
            <StarBorderIcon sx={{ color: '#EBBE49', fontSize: '20px' }} />
            <Typography variant="body2" sx={{ color: '#333' ,   fontFamily:"'Poppins', sans-serif",}}>{combinedData?.tlm_tutor_profile?.ratings} Rating | 128 Reviews</Typography>
          </Box>
        </Box>
        <Button 
          variant="contained" 
          onClick={() => navigate(`/bookyourtutor/${_id}/${combinedData?.user_id}`)}


          sx={{ backgroundColor: '#40A39B', color: '#fff', fontWeight: 500, textTransform: 'none', '&:hover': { backgroundColor: '#359c87' }, px: 4,   fontFamily:"'Poppins', sans-serif", }}
        >
          Book Tutor
        </Button>
      </Card>

      {/* About Section */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2,   fontFamily:"'Poppins', sans-serif", }}>About</Typography>
        <Typography variant="body2" sx={{ color: '#878787', lineHeight: 1.5 ,   fontFamily:"'Poppins', sans-serif",}}>
          {combinedData?.tlm_tutor_profile?.bio}
        </Typography>
      </Box>

      {/* Reviews Section */}
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 ,   fontFamily:"'Poppins', sans-serif",}}>Reviews</Typography>
        {combinedData?.reviews?.map((review, index) => (
          <Card key={review.id || index} sx={{ width: '100%', borderRadius: '9px', backgroundColor: '#D4EBFF', p: 2, mb: 2 }}>
            <Box display="flex" alignItems="center" gap={2}>
              <Avatar src={review?.Sender?.image || pic1} sx={{ width: 64, height: 64 }} />
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 600 ,   fontFamily:"'Poppins', sans-serif",}}>{review.Sender?.name}</Typography>
                <Typography variant="body2" sx={{ color: '#737373',   fontFamily:"'Poppins', sans-serif", }}>{new Date(review.createdAt).toLocaleDateString()}</Typography>
              </Box>
              <Box sx={{ ml: 'auto', textAlign: 'right' }}>
                <Typography variant="body1" sx={{ fontWeight: 600,   fontFamily:"'Poppins', sans-serif", }}>{review.rating >= 4 ? 'Very Good' : 'Good'} ({review.rating})</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {[...Array(5)].map((_, i) => (
                    <StarBorderIcon key={i} sx={{ color: i < review.rating ? '#EBBE49' : '#E6E6E6', fontSize: '20px' }} />
                  ))}
                </Box>
              </Box>
            </Box>
            <Typography variant="body2" sx={{ mt: 2, color: '#878787' ,   fontFamily:"'Poppins', sans-serif",}}>{review.review}</Typography>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default TutorDetails;
