import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Menu as MenuIcon, ExpandMore } from "@mui/icons-material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import logo from "../../assets/navbar/Logo12.png";
import arLogo from "../../assets/navbar/Taleem Logo arrrrr.png"
import SignUp from "../login screens/SignUp";
import LoginWithStudent from "../login screens/LoginWithStudent";
import LanguageDropDown from "./languageDropDown";
import { useTranslation } from "react-i18next";
import OtpScreen from "../login screens/OtpScreen";
import TutorsInfo from "../registerTutot/tutorInfo";
import UploadDocument from "../registerTutot/uploadDocs";
import WelcomeScreen from "../registerTutot/welcomeScreen";
import eng from "../../assets/navbar/united-states.png"
import arb from "../../assets/navbar/qatar1.png"
import { useDispatch } from "react-redux";
const Navbar = () => {
  const [data, setData] = useState();
  const [isStudent, setStudent] = useState(1);
  const [anchorElPrices, setAnchorElPrices] = useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [tutorsInfoOpen, setTutorsInfoOpen] = useState(false); // state for TutorsInfo
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false); // state for UploadDocument
  const [welcomeScreenOpen, setWelcomeScreenOpen] = useState(false); // state for WelcomeScreen
  const [tutorsData, setTutorsData] = useState({});
  const { t, i18n } = useTranslation();
const dispatch=useDispatch();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setPageDirection(language)
  }; 


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenPrices = (event) => {
    setAnchorElPrices(event.currentTarget);
  };

  const handleClosePrices = () => {
    setAnchorElPrices(null);
  };

  const handleUploadDocumentSubmit = () => {
    setUploadDocumentOpen(false); // Close the UploadDocument popup
    setWelcomeScreenOpen(true); // Open the WelcomeScreen popup
  };

  const handleOpenLanguage = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function setPageDirection(language) {
    const dir = language=="ar" ? "rtl" : "ltr"
    document.documentElement.dir = dir
  }

  const drawer = (
    <Box sx={{ width: 250, padding: "16px" }}>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemText primary={t("Home")} />
        </ListItem>
        <ListItem button>
          <ListItemText primary={t("Prices")} />
        </ListItem>
        <ListItem button component={Link} to="/contactus">
          <ListItemText primary={t("Contact Us")} />
        </ListItem>
        <ListItem button>
          <ListItemText primary={t("Language")} />
        </ListItem>
      </List>
      <Box
        sx={{ mt: 2, display: "flex", flexDirection: "column", gap: "16px" }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "100%",
            height: "48px",
            padding: "14px 16px",
            borderRadius: "8px",
            borderColor: "#EBBE49",
            color: "#EBBE49",
          }}
          onClick={() => {
            setSignUpOpen(true);
            setStudent(3);
          }}
        >
          {t("Join as a tutor")}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "100%",
            height: "48px",
            padding: "14px 16px",
            borderRadius: "8px",
            backgroundColor: "#40A39B",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#37a393",
            },
          }}
          onClick={() => {
            handleOpen();
            setStudent(1);
          }}
        >
          {t("Book a tutor")}
        </Button>
      </Box>
    </Box>
  );

  return (
    <AppBar
    position="static"
    sx={{
      width: "100%",
      height: "96px", // Reduced height for a more compact design
      backgroundColor: "white",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
      paddingX: { xs: "16px", sm: "24px", md: "32px" }, // Adjust padding dynamically for responsiveness
      justifyContent: "center",
    }}
  >
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "1280px", // Constrain the width for balanced content alignment
        width: "100%",
        margin: "0 auto",
      }}
    >
        {/* Logo at the Start */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
  <Link to="/">
    <Box
      component="img"
      src={i18n?.languages?.[0] === "en" ? logo : arLogo} // Select logo based on language
      alt="Logo"
      sx={{
        width: i18n?.languages?.[0] === "en" ? "170px" : "150px", // Width depends on the logo
        minWidth: "100px",
        padding: "8px 21px",
        borderRadius: "8px 0 0 0",
      }}
    />
  </Link>
</Box>


        {/* Desktop Navigation Links */}
        <Box
  sx={{
    display: { xs: "none", md: "flex" },
    gap: "30px", // Reduced gap for more compact spacing
    alignItems: "center",
    color: "#737373",
  }}
>
  {/* Home Link */}
  <Typography
    variant="body1"
    component={Link}
    to="/home"
    sx={{
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
      cursor: "pointer",
      textDecoration: "none",
      color: "black",
      marginRight:"4px",
      fontFamily:"'Poppins', sans-serif",
    }}
  >
    {t("Home")}
  </Typography>

  {/* Packages Dropdown */}
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Typography
      variant="body1"
      onClick={handleOpenPrices}
      sx={{
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "20px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        marginRight: "1px", // Ensures tighter alignment with the dropdown icon
        fontFamily:"'Poppins', sans-serif",
      }}
    >
      {t("Packages")}
      <ExpandMore sx={{ marginLeft: "0.5px" }} />
    </Typography>
    <Menu
    anchorEl={anchorElPrices}
    open={Boolean(anchorElPrices)}
    onClose={handleClosePrices}
    sx={{
      "& .MuiPaper-root": {
        borderRadius: "8px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        minWidth: "180px",
      },
    }}
  >
    <MenuItem
      onClick={handleClosePrices}
      sx={{
        fontSize: "14px",
        fontWeight: 400,
        "&:hover": {
          backgroundColor: "primary.light",
          color: "primary.contrastText",
        },
      }}
    >
      {t("Package 1")}
    </MenuItem>

    <MenuItem
      onClick={handleClosePrices}
      sx={{
        fontSize: "14px",
        fontWeight: 400,
        "&:hover": {
          backgroundColor: "primary.light",
          color: "primary.contrastText",
        },
      }}
    >
      {t("Package 2")}
    </MenuItem>
    <MenuItem
      onClick={handleClosePrices}
      sx={{
        fontSize: "14px",
        fontWeight: 400,
        "&:hover": {
          backgroundColor: "primary.light",
          color: "primary.contrastText",
        },
      }}
    >
      {t("Package 3")}
    </MenuItem>
  </Menu>
  </Box>

  {/* Contact Us Link */}
  <Typography
    variant="body1"
    component={Link}
    to="/contactus"
    sx={{
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "20px",
      cursor: "pointer",
      textDecoration: "none",
      color: "inherit",
      fontFamily:"'Poppins', sans-serif",
    }}
  >
    {t("Contact Us")}
  </Typography>
</Box>


        {/* Desktop Buttons */}
        <Box
  sx={{
    display: { xs: "none", md: "flex" },
    gap: "11px", // Reduced gap between buttons
    alignItems: "center",
  }}
>
  {/* Join as a Tutor Button */}
  <Button
    variant="outlined"
    sx={{
      width: "160px",
      height: "44px", // Slightly smaller for compact UI
      padding: "12px 16px",
      borderRadius: "8px",
      borderColor: "#EBBE49",
      textTransform: "none",
      fontWeight: 500,
      fontSize: "15px", // Slightly smaller for balance
      color: "#EBBE49",
      fontFamily:"'Poppins', sans-serif",
      "&:hover": {
        backgroundColor: "#FAF4E1",
      },
    }}
    onClick={() => {
      setSignUpOpen(true);
      setStudent(3);
    }}
  >
    {t("Join as a tutor")}
  </Button>

  {/* Book a Tutor Button */}
  <Button
    variant="contained"
    sx={{
      width: "140px",
      height: "44px",
      padding: "12px 16px",
      borderRadius: "8px",
      backgroundColor: "#40A39B",
      textTransform: "none",
      fontWeight: 500,
      fontSize: "15px",
       fontFamily:"'Poppins', sans-serif",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#37a393",
      },
    }}
    onClick={() => {
      handleOpen();
      setStudent(1);
    }}
  >
    {t("Book a tutor")}
  </Button>

  {/* Language Dropdown */}
  <Box
    sx={{
      display: { xs: "none", md: "flex" },
      alignItems: "center",
      color: "#737373",
      ml: 2, // Slight spacing from buttons
    }}
  >
    <Box>
      <Typography
        variant="body1"
        onClick={handleOpenLanguage}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <img
          src={localStorage.getItem("lang") === "ar" ? arb : eng}
          alt="language-flag"
          style={{
            width: "24px",
            height: "24px",
            marginRight: "8px",
          }}
        />
        <ExpandMore />
      </Typography>
      <Menu
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        onClose={handleCloseLanguage}
        sx={{
          mt: 1, // Adds slight spacing below the dropdown trigger
          "& .MuiPaper-root": {
            borderRadius: "8px",
            border: "1px solid #E0E0E0", // Light border for dropdown
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <MenuItem
          onClick={(e) => {
            localStorage.setItem("lang", "en");
            changeLanguage("en");
            handleCloseLanguage(e);
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px", // Consistent spacing between icon and text
            fontSize: "14px", // Compact font size
            "&:hover": {
              backgroundColor: "#F0F0F0",
            },
          }}
        >
          <img
            src={eng}
            alt="English"
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          English
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            localStorage.setItem("lang", "ar");
            changeLanguage("ar");
            handleCloseLanguage(e);
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            fontSize: "14px",
            "&:hover": {
              backgroundColor: "#F0F0F0",
            },
          }}
        >
          <img
            src={arb}
            alt="Arabic"
            style={{
              width: "20px",
              height: "20px",
            }}
          />
          Arabic
        </MenuItem>
      </Menu>
    </Box>
  </Box>
</Box>


   
        {/* Mobile Menu Icon at the End */}
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        {drawer}
      </Drawer>
      <SignUp
        open={signUpOpen}
        handleClose={() => setSignUpOpen(false)}
        isStudent={isStudent}
        data={data}
        setData={setData}
        otpOpen={otpOpen}
        setOtpOpen={setOtpOpen}
        handleSubmit={() => {
          setSignUpOpen(false);
        }}
        signupOpen={setOpen}
      />
      <LoginWithStudent
        open={open}
        handleClose={handleClose}
        isStudent={isStudent}
        data={data}
        setData={setData}
        otpOpen={otpOpen}
        setOtpOpen={setOtpOpen}
        signUpOpen={signUpOpen}
        setSignUpOpen={setSignUpOpen}
        // loginWithEmailOpen={loginWithEmailOpen}
        signupOpen={setOpen}
      />
      <OtpScreen
        open={otpOpen}
        handleClose={() => setOtpOpen(false)}
        isStudent={isStudent}
        data={data}
        setData={setData}
        tutors
        InfoOpen={() => setTutorsInfoOpen(true)}
      />
      <TutorsInfo
        open={tutorsInfoOpen}
        handleClose={() => setTutorsInfoOpen(false)}
        // handleSubmit={handleTutorsInfoSubmit} // New popup for TutorsInfo
        data={data}
        setData={setData}
        uploadDocumentOpen={() => {
          setUploadDocumentOpen(true);
        }}
        tutorsData={tutorsData}
        setTutorsData={setTutorsData}
      />
      <UploadDocument
        open={uploadDocumentOpen}
        handleClose={() => {
          setUploadDocumentOpen(false);
          setWelcomeScreenOpen(true);
        }}
        handleSubmit={handleUploadDocumentSubmit} // New popup for UploadDocument
        data={data}
        setData={setData}
        tutorsData={tutorsData}
        setTutorsData={setTutorsData}
      />
      <WelcomeScreen
        open={welcomeScreenOpen}
        handleClose={() => setWelcomeScreenOpen(false)} // New popup for WelcomeScreen
      />
    </AppBar>
  );
};

export default Navbar;
