import React, { useState } from "react";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import PromoPackagesCards from "./PromoPackagesCards ";

const PromoPackages = ({ children }) => {
  const [selected, setSelected] = useState("online");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const buttonStyles = (isSelected) => ({
    width: "119.03px",
    height: "29.76px",
    padding: "6.76px 34.21px 6.99px 33.82px",
    gap: "10.82px",
    borderRadius: "21.64px",
    backgroundColor: isSelected ? "#40A39B" : "transparent",
    color: isSelected ? "#FFFFFF" : "#B6B7B6",
    fontWeight: 600,
    fontSize: "16.23px",
    textTransform: "none",
    fontFamily: "'Poppins', sans-serif",
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: isMobile ? "1px" : "65px", // Adjust margin for mobile
        padding: isMobile ? "0" : { xs: "20px 10px", md: "40px 0" }, // Remove padding for mobile
      }}
    >
      {/* Heading */}
      <Typography
        sx={(theme) => ({
          fontFamily: "'Poppins', sans-serif",
          fontSize: { xs: "20px", sm: "24px", md: "36px" },
          fontWeight: 700,
          lineHeight: { xs: "28px", sm: "32px", md: "36px" },
          textAlign: "center",
          color: "#000000",
          padding: "0 20px",
          marginBottom: isMobile ? "10px" : "15px", // Reduce spacing for mobile
        })}
      >
        What are the <span style={{ color: "#40A39B" }}>Packages</span> we
        offer?
      </Typography>

      {/* Selection Buttons */}
      <Box
        sx={{
          width: "234px",
          height: "51.4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10.82px",
          marginTop: "22px",
          border: "1px solid #40A39B",
          borderRadius: "36.52px",
          padding: "10.82px",
        }}
      >
        <Button
          onClick={() => setSelected("online")}
          sx={buttonStyles(selected === "online")}
        >
          Online
        </Button>

        <Button
          onClick={() => setSelected("offline")}
          sx={buttonStyles(selected === "offline")}
        >
          Offline
        </Button>
      </Box>

      {/* Card Wrapper */}
      <Box
        sx={{
          marginTop: isMobile ? "20px" : "30px", // Adjust spacing above cards for mobile
          display: "flex",
          flexWrap: "wrap",
          gap: isMobile ? "8px" : "16px", // Reduce gap between cards for mobile
          justifyContent: "center",
          padding: isMobile ? "0" : "initial", // Remove additional padding in mobile
        }}
      >
        <PromoPackagesCards />
      </Box>
    </Box>
  );
};

export default PromoPackages;
