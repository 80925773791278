import React from "react";
import {
  Box,
  Typography,
  InputBase,
  Button,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NavbarPromo from "./NavbarPromo";

const MainSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const SearchBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
    padding: isMobile ? "10px" : "14px 20px",
    gap: isMobile ? "8px" : "16px",
    borderRadius: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 15px 0px #0000001A",
    position: isMobile ? "static" : "absolute",
    bottom: isMobile ? "auto" : "-80px",
    left: isMobile ? "auto" : "50%",
    transform: isMobile ? "none" : "translateX(-50%)",
    marginTop: isMobile ? "30px" : "0",
    zIndex: 2,
  }));

  const InputBaseStyled = styled(InputBase)(({ theme }) => ({
    flex: 1,
    fontSize: isMobile ? "14px" : "16px",
    fontFamily: "'Poppins', sans-serif",
  }));

  const SearchButton = styled(Button)(({ theme }) => ({
    minWidth: "100px",
    borderRadius: "9px",
    padding: isMobile ? "10px 16px" : "16px 38px",
    textTransform: "none",
    backgroundColor: "#40A39B",
    color: "#fff",
    fontFamily: "'Poppins', sans-serif",
    fontSize: isMobile ? "14px" : "16px",
    "&:hover": {
      backgroundColor: "#37a393",
    },
  }));

  const responsiveText = {
    heading: {
      fontWeight: 600,
      fontSize: {
        xs: "24px",
        sm: "36px",
        md: "48px",
      },
      lineHeight: "1.2",
      marginTop: isMobile ? "65px" : "0",
    },
    subheading: {
      marginTop: isMobile ? "25px" : "12px",
      fontWeight: 400,
      fontSize: {
        xs: "14px",
        sm: "16px",
        md: "18px",
      },
      lineHeight: "1.5",
    },
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: { xs: "400px", md: "650px" },
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Video Background */}
        <video
          src={`${process.env.REACT_APP_BASE_URL}content/1733214802440-mainPageVideo.mp4`}
          autoPlay
          muted
          loop
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            zIndex: -2,
          }}
        />
        {/* Video Overlay */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            background: "#00000080",
            position: "absolute",
            zIndex: -1,
          }}
        />
        {/* Navbar */}
        <Box
          sx={{
            position: "absolute",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "1200px",
            width: "calc(100% - 40px)",
            mx: "auto",
          }}
        >
          <NavbarPromo />
        </Box>
        {/* Centered Content */}
        <Box
          sx={{
            maxWidth: "979px",
            width: "90%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "#fff",
          }}
        >
          <Typography sx={responsiveText.heading}>
            Connect With Your Perfect Tutor For Learning.
          </Typography>
          <Typography sx={responsiveText.subheading}>
            Whether you're a student eager to learn or a tutor ready to teach,
            our platform connects you with endless opportunities. Join us
            today.
          </Typography>

          {/* Search Box Positioned Just Below the Heading */}
          {isMobile && (
            <SearchBox>
              <SearchIcon sx={{ color: "black", marginRight: "8px" }} />
              <InputBaseStyled placeholder="Search Tutor, Subject, Courses, etc." />
              <SearchButton>Search</SearchButton>
            </SearchBox>
          )}
        </Box>
      </Box>

      {/* Search Box Positioned Between Sections for Desktop */}
      {!isMobile && (
        <SearchBox>
          <SearchIcon sx={{ color: "black", marginRight: "8px" }} />
          <InputBaseStyled placeholder="Search Tutor, Subject, Courses, etc." />
          <SearchButton>Search</SearchButton>
        </SearchBox>
      )}

      {/* Split Section */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px",
          background: "white",
        }}
      >
        {/* Main Section Content */}
      </Box>
    </>
  );
};

export default MainSection;
