import React, { useEffect } from 'react';
import { Box, Typography, Breadcrumbs, Link } from '@mui/material';
import PromotionalBanner from '../common comps/promotionalbanner';
import SidebarMyClasses from '../common comps/SidebarMyClasses';
import { getProfile } from '../../Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MyClassLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getProfileData = useSelector(
    (state) => state.getProfileReducer?.getProfileData
  );

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const handleHomeClick = (event) => {
    event.preventDefault(); // Prevent default link behavior

    // Check if user_type is "3" and navigate accordingly
    if (getProfileData?.user_type === "3") {
      navigate('/tutorMain');
    } else {
      navigate('/home');
    }
  };


  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '16px',
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        {/* Promotional Banner */}
        <PromotionalBanner />

       
        {/* Main Layout */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            gap: '86px',
          }}
        >
          {/* Sidebar */}
          <Box
            sx={{
              width: {
                xs: '100%',
                md: '300px',
              },
            }}
          >
            <SidebarMyClasses />
          </Box>

          {/* Content Area */}
          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: '#fff',
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '16px',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyClassLayout;
