// Layout.jsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import LoggedInFooter from '../components/footer/loggedInFooter';
import LoggedInNavbar from "../components/navbar/loggedInnavbar"
import Promolandingpage from '../components/promo landingpage/promolandingpage';
const Layout = ({ children }) => {
  const location = useLocation();
  const noLayoutRoutes = ['/','/termsofservice','/privacypolicy','/contactus']; 

  const shouldDisplayLayout = !noLayoutRoutes.includes(location.pathname);

  return (
    <>      
    {location.pathname=="/promo_web" || location.pathname=="/" ? ( 
      <main>{children}</main>)
    : (
      <>
    {shouldDisplayLayout ? (<LoggedInNavbar />) : (<Header/>)}
      {/* <LoggedInNavbar/> */}
      {/* {shouldDisplayLayout && <NavBar />} */}
      <main>{children}</main>
      {shouldDisplayLayout ? (<LoggedInFooter />) : (<Footer/>)}
    </>
  )}
    </>    
  );
};

export default Layout;
