import React, { useState, useEffect } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { faqs } from '../../Redux/Actions';

const Faq = () => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const faqsData = useSelector((state) => state?.faqsReducer?.faqsData?.data  || []); // Safe access to faqsData
console.log(faqsData,"faqsData");
  useEffect(() => {
    dispatch(faqs()); // Fetch FAQs on component mount
  }, [dispatch]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        width: '80%',
        maxWidth: '900px',
        margin: '0 auto',
        padding: '40px 0',
        textAlign: 'center',
      }}
    >
      <Typography
        sx={{
          fontFamily:"'Poppins', sans-serif",
          fontSize: '32px',
          fontWeight: 700,
          lineHeight: '36px',
          textAlign: 'center',
          marginBottom: '24px',
        }}
      >
        {t('FrequentlyAskedQuestions')}
      </Typography>

      {faqsData.length > 0 ? (
        faqsData.map((item, index) => (
          <Box key={index}>
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{
                boxShadow: 'none',
                '&:before': { display: 'none' },
                borderBottom: 'none',
                marginBottom: '0px',
              }}
            >
              <AccordionSummary
                expandIcon={
                  <Box
                    sx={{
                      backgroundColor: expanded === `panel${index}` ? '#40A39B' : '#FFFFFF',
                      borderRadius: '50%',
                      width: '36px',
                      height: '36px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #E6E6E6',
                    }}
                  >
                    <ExpandMoreIcon sx={{ color: expanded === `panel${index}` ? '#FFFFFF' : '#000' }} />
                  </Box>
                }
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                sx={{
                  padding: '16px',
                  '& .MuiAccordionSummary-content': {
                    fontFamily: 'Metropolis',
                    fontSize: '24px',
                    fontWeight: 500,
                    lineHeight: '28px',
                    textAlign: 'left',
                    width: '95%',
                  },
                }}
              >
                <Typography sx={{ fontWeight: 500  ,fontSize: '24px',   fontFamily:"'Poppins', sans-serif",}}>{t(item.question)}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontFamily: 'Metropolis',
                  fontSize: '22px',
                  fontWeight: 430,
                  lineHeight: '22px',
                  textAlign: 'left',
                  padding: '16px',
                  width: '95%',
                  color: '#737373',
               
                }}
              >
                {t(item.answer)}
              </AccordionDetails>
            </Accordion>

            <Box
              sx={{
                height: '1px',
                backgroundColor: '#E6E6E6',
                width: '100%',
                margin: '16px 0',
              }}
            />
          </Box>
        ))
      ) : (
        <Typography>{t('No FAQs available')}</Typography> // Fallback message
      )}
    </Box>
  );
};

export default Faq;
