import React from "react";
import { Box, Typography, Button } from "@mui/material";
import bgimage from "../../assets/IMG.png";

const PrivacyPolicyPromo = () => {
  return (
    <Box
      sx={{
        backgroundImage: { xs: "none", md: `url(${bgimage})` },
        backgroundColor: { xs: "rgba(251, 251, 251, 1)", md: "transparent" },
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: { xs: "5% 3%", md: "2% 5%" },
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FBFBFB",
          padding: { xs: "12px", md: "20px" },
          borderRadius: "10px",
          maxWidth: "700px",
          marginBottom: "10px",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: 700,
            fontSize: { xs: "20px", md: "32px" },
            marginBottom: { xs: "8px", md: "10px" },
            color: "#000",
          }}
        >
          Privacy Policy
        </Typography>

        <Typography
          sx={{
            fontWeight: 400,
            fontSize: { xs: "14px", md: "20px" },
            marginBottom: { xs: "10px", md: "15px" },
            color: "#737373",
          }}
        >
          Let’s have a look at our terms and policies
        </Typography>

        <Typography
          sx={{
            fontWeight: 400,
            fontSize: { xs: "12px", md: "18px" },
            color: "#4A4A4A",
            marginBottom: { xs: "20px", md: "30px" },
            lineHeight: { xs: "18px", md: "28px" },
          }}
        >
          At Taleem, we respect and protect your privacy. This Privacy Policy
          outlines how we collect, use, disclose, and safeguard your
          information when you visit our website and use our services. By using
          our platform, you agree to the collection and use of information in
          accordance with this policy.
        </Typography>

        <Button
          sx={{
            backgroundColor: "#40A39B",
            color: "#fff",
            width: { xs: "auto", md: "155px" }, // Flexible width for mobile
            height: { xs: "40px", md: "47px" },
            padding: { xs: "8px 16px", md: "12px 29px" }, // Better padding for mobile
            borderRadius: "9.19px",
            fontSize: { xs: "13px", md: "16px" }, // Adjusted font size
            fontWeight: 500,
            lineHeight: "1.5", // Improved readability
            textTransform: "none", // Prevent text from being capitalized
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#33867D",
            },
          }}
        >
          Read More
        </Button>
      </Box>
    </Box>
  );
};

export default PrivacyPolicyPromo;
