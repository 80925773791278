import React from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MyClassLayout from '../../my classes dashboard/MyClassLayout';
import avatarImage from "../../../assets/chats/image.png";

const NotificationBox = () => {
  const navigate = useNavigate();

  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: { xs: '100%', sm: '80%', md: '722px' },
        height: { xs: 'auto', sm: '78px' },
        padding: { xs: '12px 16px', sm: '20px 32px' },
        gap: '16px',
        borderRadius: '20px',
        cursor: 'pointer',
        flexDirection: { xs: 'column', sm: 'row' },
        textAlign: { xs: 'center', sm: 'left' },
      }}
      onClick={() => navigate('/ChatBox')}
    >
      <Avatar
        src={avatarImage}
        alt="avatar"
        sx={{ width: 38, height: 38 }}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 'bold', fontSize: { xs: '0.9rem', sm: '1rem' } }}
        >
          Naseem
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ color: '#737373', fontSize: { xs: '0.8rem', sm: '0.9rem' } }}
        >
          Hello Sir, I will be reaching a little late due to traffic.
        </Typography>
      </Box>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ color: '#737373', fontSize: { xs: '0.7rem', sm: '0.8rem' } }}
      >
        5 min ago
      </Typography>
    </Paper>
  );
};

const ChatNotificationsPage = () => {
  return (
    <MyClassLayout>
      <Box sx={{ padding: { xs: '16px', sm: '32px' } }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', mb: { xs: 2, sm: 3 }, fontSize: { xs: '1.5rem', sm: '2rem' } }}
        >
          Chats
        </Typography>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <NotificationBox />
          <NotificationBox />
          <NotificationBox />
          <NotificationBox />
        </Box>
      </Box>
    </MyClassLayout>
  );
};

export default ChatNotificationsPage;
