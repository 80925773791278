import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import leftArrow from "../../assets/raring/_xmark (1).png";
import rightArrow from "../../assets/raring/_xmark (2).png";
import girlImg from "../../assets/Chat.png";
import ratingStar from "../../assets/rating.png";

const RatingsPromo = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        background: "linear-gradient(to right, rgba(255, 255, 255, 1) 40%, rgba(64, 163, 155, 0.35) 60%)",
        padding: { xs: "20px 10px", md: "40px 20px" },
        backdropFilter: "blur(400px)",
      }}
    >
      {/* Top Heading */}
      <Typography
        sx={{
          fontSize: { xs: "20px", md: "32px" }, // Reduced for mobile
          fontWeight: "700",
          lineHeight: { xs: "26px", md: "38px" },
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        Rating & Review
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "16px", md: "24px" }, // Reduced for mobile
          color: "#737373",
          lineHeight: { xs: "22px", md: "32px" },
          fontFamily: "'Poppins', sans-serif",
          marginTop: "15px",
        }}
      >
        What People Say About Taleem
      </Typography>

      {/* Main Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "40px",
          padding: { xs: "10px", md: "4px 80px" },
          marginBottom: "25px",
        }}
      >
        {/* Left Arrow */}
        <Box
          component="img"
          src={leftArrow}
          alt="Left Arrow"
          sx={{
            cursor: "pointer",
            marginLeft: { xs: "0", md: "10px" },
            width: "46px",
            height: "46px",
            display: { xs: "none", md: "block" }, // Hidden on mobile
          }}
        />

        {/* Main Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: { xs: "20px", md: "30px" },
            justifyContent: { xs: "center", md: "space-between" }, // Center content on mobile
            ml: { md: 10 },
          }}
        >
          {/* Left Side - Girl Image */}
          <Box
            component="img"
            src={girlImg}
            alt="Girl"
            sx={{
              width: { xs: "150px", md: "280px" }, // Smaller for mobile
              height: "auto",
              objectFit: "cover",
              margin: { xs: "0 auto", md: "0" }, // Center image on mobile
            }}
          />

          {/* Divider */}
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderRightWidth: "2px",
              borderColor: "#26B6A6",
              height: { xs: "80px", md: "312px" }, // Reduced height for mobile
              display: { xs: "none", md: "block" },
            }}
          />

          {/* Right Side - Testimonial */}
          <Box
            sx={{
              textAlign: { xs: "center", md: "left" },
              flex: 2,
              maxWidth: "670px",
              marginRight: { md: "20px" },
            }}
          >
            {/* Rating Stars */}
            <Box
              component="img"
              src={ratingStar}
              alt="Rating Stars"
              sx={{
                marginBottom: "20px",
                width: { xs: "80px", md: "138px" }, // Smaller for mobile
              }}
            />

            {/* Testimonial Text */}
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: { xs: "14px", md: "18px" }, // Reduced for mobile
                color: "#4A4A4A",
                lineHeight: { xs: "20px", md: "28px" },
                marginBottom: "30px",
              }}
            >
              “As a tutor on this platform, my experience has been amazing! The
              user interface is incredibly intuitive, making it easy to connect
              with students and manage my lessons. The flexibility in scheduling
              and the variety of subjects I can teach has been a game-changer.”
            </Typography>

            {/* Author Name */}
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: { xs: "20px", md: "30px" }, // Reduced for mobile
                color: "#000000",
                marginBottom: "9px",
              }}
            >
              Andrea Monroy
            </Typography>

            {/* Subscriber Since */}
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: "14px", md: "18px" }, // Reduced for mobile
                color: "#454545",
              }}
            >
              Subscriber since 2016
            </Typography>
          </Box>
        </Box>

        {/* Right Arrow */}
        <Box
          component="img"
          src={rightArrow}
          alt="Right Arrow"
          sx={{
            cursor: "pointer",
            marginRight: { xs: "0", md: "10px" },
            width: "46px",
            height: "46px",
            display: { xs: "none", md: "block" }, // Hidden on mobile
          }}
        />
      </Box>
    </Box>
  );
};

export default RatingsPromo;
