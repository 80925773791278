import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
  IconButton,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Menu as MenuIcon, Notifications, ExpandMore } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import logo from "../../assets/navbar/Logo12.png";
import arLogo from "../../assets/navbar/Taleem Logo arrrrr.png"
import avatarImage from '../../assets/navbar/image.png';
import DropdownProfileMenu from './DropdownProfileMenu';
import NotificationPopup from './NotificationPopup.jsx';
import { getProfile } from '../../Redux/Actions/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import eng from "../../assets/navbar/united-states.png"
import arb from "../../assets/navbar/qatar1.png"
import { useTranslation } from "react-i18next";

const LoggedInNavbar = () => {
  const location = useLocation();
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setPageDirection(language)
  };
  const navigate = useNavigate(); // Initialize navigate

  const handleRedirect = () => {
    navigate("/myclasses");
  };

  const handleRedirectStudent = () => {
    navigate("/schedulestudent");
  };

  const dispatch = useDispatch();
  const getProfileData = useSelector(
    (state) => state.getProfileReducer?.getProfileData
  );

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  function setPageDirection(language) {
    const dir = language=="ar" ? "rtl" : "ltr"
    document.documentElement.dir = dir
  }


  const handleOpenLanguage = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const closeModals = () => {
    setNotificationsOpen(false);
    setAnchorElLanguage(null); // Close language menu
  };
  const handleProfileDropdownClick = debounce((event) => {
    event.stopPropagation();
    setAnchorElLanguage(null); // Close profile dropdown
    console.log("Profile dropdown closed");
  }, 200); // Adjust debounce time as needed
  const handleLogoClick = () => {
    closeModals();
    navigate("/home"); // Route to home
  };

  const handleHomeClick = () => {
    // Check if user_type is "3" and navigate accordingly
    if (getProfileData?.user_type == 3) {
      navigate("/tutorMain");
    } else {
      navigate("/home"); // Replace '/home' with the default route for other user types
    }
  };

  useEffect(() => {
    closeModals();
    setAnchorElLanguage(null);
  }, [location.pathname]);

  const drawer = (
    <Box sx={{ width: 250, padding: "16px" }}>
      <List>
        <ListItem button onClick={handleHomeClick}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Prices" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Contact Us" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Language" />
        </ListItem>
      </List>
    </Box>
  );

  const notifications = [
    {
      title: "Booking Confirmed",
      description: "Your booking has been confirmed.",
    },
    { title: "New Offer", description: "50% off on your next trip!" },
  ];

  return (
    <AppBar
      position="static"
      sx={{
        width: "100%",
        height: "96px",
        backgroundColor: "white",
        boxShadow: "0px 1px 3px rgba(0,0,0,0.1)",
        padding: "0 24px",
        justifyContent: "center",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: "1440px",
          margin: "0 auto",
          width: "100%",
        }}
      >
        {/* Logo */}
        <Box sx={{ display: "flex", alignItems: "center" }}>

    <Box
      component="img"
      src={i18n?.languages?.[0] === "en" ? logo : arLogo} // Select logo based on language
      alt="Logo"
      sx={{
        width: i18n?.languages?.[0] === "en" ? "170px" : "150px", // Width depends on the logo
        minWidth: "100px",
        padding: "8px 21px",
        borderRadius: "8px 0 0 0",
      }}
    />
 
</Box>

        {/* Desktop Navigation Links */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: "40px",
            alignItems: "center",
            color: "#737373",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, fontSize: "16px", cursor: "pointer" ,color:"#40A39B"}}
            onClick={handleHomeClick}
          >
            Home
          </Typography>

          <Typography
            variant="body1"
            sx={{ fontWeight: 400, fontSize: "16px", cursor: "pointer" }}
            onClick={handleRedirect}
          >
            My Bookings
          </Typography>

          {getProfileData?.user_type == 3 && (
            <Typography
              variant="body1"
              sx={{ fontWeight: 400, fontSize: "16px", cursor: "pointer" }}
              onClick={handleRedirectStudent}
            >
              Schedule
            </Typography>
          )}

<Box >
      <Typography
        variant="body1"
        onClick={handleOpenLanguage}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <img
          src={localStorage.getItem("lang") === "ar" ? arb : eng}
          alt="language-flag"
          style={{
            width: "24px",
            height: "24px",
            marginRight: "8px",
          }}
        />
        <ExpandMore />
      </Typography>
      <Menu
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        onClose={handleCloseLanguage}
      >
        <MenuItem
          onClick={(e) => {
            localStorage.setItem("lang", "en");
            changeLanguage("en");
            handleCloseLanguage(e);
          }}
        >
          <img
            src={eng}
            alt="English"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "8px",
            }}
          />
          English
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            localStorage.setItem("lang", "ar");
            changeLanguage("ar");
            handleCloseLanguage(e);
          }}
        >
          <img
            src={arb}
            alt="Arabic"
            style={{
              width: "20px",
              height: "20px",
              marginRight: "8px",
            }}
          />
          Arabic
        </MenuItem>
      </Menu>
    </Box>
        </Box>

        {/* Desktop Notification & User Menu */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: "16px",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={toggleNotifications}
            sx={{
              backgroundColor: "#40A39B",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#37a393",
              },
              borderRadius: "50%",
              width: "40px",
              height: "40px",
            }}
          >
            <Notifications />
          </IconButton>
          <Avatar alt="Naseem" src={avatarImage} sx={{ marginLeft: "8px" }} />
          <DropdownProfileMenu
  userName={getProfileData?.name}
  open={Boolean(anchorElLanguage)}
  onClose={handleProfileDropdownClick} // Profile dropdown uses debounce
/>
        </Box>

        {/* Mobile Menu Icon */}
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        {drawer}
      </Drawer>

      {/* Notification Popup */}
      <NotificationPopup
        open={notificationsOpen}
        onClose={toggleNotifications}
        notifications={notifications}
        setNotificationsOpen={setNotificationsOpen}
      />
    </AppBar>
  );
};

export default LoggedInNavbar;
