import { signup, sendMobileOtpSignup } from "../Redux/Actions";

const ActionName = {
    sendMobileOtpSignup:"sendMobileOtpSignup",
    verifyMobileOtpSignup:"verifyMobileOtpSignup",
    signup:"signup",
    login:"emailLogin",
    SET_FORM_DATA:"SET_FORM_DATA",
    sendMobileOtpLogin:"sendMobileOtpLogin",
    verifyMobileOtpLogin:"verifyMobileOtpLogin",
    forgetPassword:"forgetPassword",
    getCategoryWithProduct:"getCategoryWithProduct",
    Home:"Home",
    getProduct:"getProduct",
    topTutors:"topTutors",
    socialSignIn: "socialSignIn",
    verifyOtpEmChanges:"verifyOtpEmChanges",
    sendOtpEmChanges:"sendOtpEmChanges",
    
     //list from admin,
     expertise:"expertise",
     qualifications:"qualifications",
     grades:"grades",
     curriculums:"curriculums",
     sessions:"sessions",
     subjects:"subjects",
     packageTypes:"packageTypes",
     classNschedules:"classNschedules",

     //Profile
     getProfile:"getProfile",
     updateProfile:"updateProfile",
     updateTutorProfile:"updateTutorProfile",
     updateTutorDocument:"updateTutorDocument",
     getTutorProfile:"getTutorProfile",
     getStudentProfile:"getStudentProfile",
     updateStudentProfile: "updateStudentProfile",
     updateAcadmicDetails:"updateAcadmicDetails",

     //rate card actions
     getRateCard:"getRateCard",
     addRateCard:"addRateCard",
     //landing page 
     faqs:"faqs",

     getSchedules:"getSchedules",
     schedules:"schedules",
     //homepage student
     getStudentDetails:"getStudentDetails",
     getCurriculums:"getCurriculums",
     getGradeClass:"getGradeClass",
     getExpertise:"getExpertise",

     //tutor details
     getRecreationalTutorDetails:"getRecreationalTutorDetails",
     getAcadmicTutorDetails:"getAcadmicTutorDetails",
     getCourseDetails:"getCourseDetails",
     getCourseCategories:"getCourseCategories",
     getCourseDescription:"getCourseDescription",
     getTutorDescription:"getTutorDescription",
     getRecreationalDescription:"getRecreationalDescription",
     profileStatus:"profileStatus",

     //policies
     getContent:"getContent",
     getAvalability:"getAvalability",
     getClasstype:"getClasstype"

};

export default ActionName;