import React, { useEffect, useState } from "react";
import { Box, Typography, Modal, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendMobileOtpSignup, verifyMobileOtpLogin } from "../../Redux/Actions";
import toast from "react-hot-toast";
import CountdownTimer from "../reusables/countdownTimer";
import OtpInput from "react-otp-input";

const OtpScreen = ({
  open,
  handleClose,
  isStudent,
  data,
  setData,
  otpOpen,
  setOtpOpen,
  tutorsInfoOpen,
  uploadDocumentOpen,
  setWelcomeScreen,
  openAprrovalScreen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(""); // Initialize OTP as a string to work with react-otp-input
  const [isDisplayResend, setIsDisplayResend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timerKey, setTimerKey] = useState(0);

  const handleComplete = () => {
    setIsDisplayResend(true);
  };

  useEffect(() => {
    console.log(data, "valueDATA");
  }, [data]);

  const handleResend = () => {
    let payload = {
      type: "mobile_no",
      field_value: data?.data,
      country_code: data?.country_code,
      action: data?.type,
      user_type: `${data?.user}`,
    };
    dispatch(
      sendMobileOtpSignup(payload, (res) => {
        // setData({
        //   token: res?.data?.data?.token,
        //   user: isStudent,
        //   type: "login",
        //   ismob: true,
        //   data: phoneWithoutCountryCode,
        //   country_code: countryCode,
        // });
      })
    );
    if (isDisplayResend) {
      setTimerKey((prevKey) => prevKey + 1); // Update the timer key to restart the timer
      setIsDisplayResend(false);
    }
  };

  const handleVerify = () => {
    const payload = {
      otp: otp,
      type: data?.ismob ? "mobile_no" : "email",
      field_value: data?.data,
      action: data?.type,
      token: data?.token,
      country_code: data?.country_code,
    };
    setLoading(true); // Disable the button

    dispatch(
      verifyMobileOtpLogin(payload, (res) => {
        if (res?.status === 200) {
          localStorage.setItem("token", res?.data?.data?.token);
          localStorage.setItem("user", JSON.stringify(res?.data?.data));
          if (data?.type === "signup" && data?.user == 3) {
            handleClose();
            resetForm();
            // navigate("/tutorMain", { isStudent });
            tutorsInfoOpen();
          } else if (data?.user == 3) {
            switch (res?.data?.data?.tutorStatus) {
              case "0":
                console.log("pending form");
                if (res?.data?.data?.tutorProfileCompletionStep == "0") {
                  tutorsInfoOpen();
                } else if (res?.data?.data?.tutorProfileCompletionStep == "1") {
                  uploadDocumentOpen();
                } else if (res?.data?.data?.tutorProfileCompletionStep == "2") {
                  openAprrovalScreen();
                } else {
                  setWelcomeScreen();
                }
                handleClose();
                break;
              case "1":
                resetForm();
                navigate("/tutorMain", { isStudent });
                handleClose();
                break;
              case "2":
                
                openAprrovalScreen();
                console.log("form rejected,error");
                handleClose();
                break;
              case "3":
                setWelcomeScreen();
                console.log("approval pending from admin");
                handleClose();
                break;
              default:
                console.log("approval pending from admin");
                handleClose();
            }
          } else {
            resetForm();
            navigate("/home", { isStudent });
          }
        } else {
          toast.error("Verification failed. Please try again.");
        }
      })
    );

    setTimeout(() => {
      setLoading(false); // Re-enable the button after 3 seconds
    }, 3000);
  };

  const handleOtpChange = (value) => {
    // Allow only numeric characters
    if (!isNaN(value)) {
      setOtp(value);
    }
    // const numericValue = value.replace(/\D/g, "");
  };

  const resetForm = () => {
    setOtp("");
    setIsDisplayResend(false);
    setTimerKey(0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && otp.length === 4 && !loading) {
      handleVerify();
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) =>
        reason === "backdropClick" && handleClose() && resetForm()
      }
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "400px", md: "450px" },
          bgcolor: "white",
          borderRadius: "8px",
          boxShadow: 24,
          p: { xs: 2, sm: 4 },
          textAlign: "center",
        }}
        onKeyDown={handleKeyDown}
      >
        <IconButton
          onClick={() => {
            resetForm();
            handleClose();
          }}
          sx={{
            position: "absolute",
            top: { xs: "8px", sm: "16px" },
            right: { xs: "8px", sm: "16px" },
            width: { xs: "28px", sm: "32px" },
            height: { xs: "28px", sm: "32px" },
            backgroundColor: "#F2F2F2",
            "&:hover": { backgroundColor: "#E0E0E0" },
          }}
        >
          <CloseIcon sx={{ color: "#5F6368" }} />
        </IconButton>

        <Typography
          variant="h6"
          fontWeight={700}
          mb={2}
          fontSize={{ xs: "20px", sm: "24px" }}
        >
          Enter OTP
        </Typography>

        <Typography
          variant="body1"
          mb={3}
          color="text.secondary"
          fontSize={{ xs: "14px", sm: "16px" }}
        >
          We've sent an OTP password to your registered <br />
          mobile number.
        </Typography>

        <OtpInput
          value={otp}
          onChange={handleOtpChange}
          numInputs={4}
          isInputNum={true}
          shouldAutoFocus
          inputStyle={{
            width: "54px",
            height: "48px",
            margin: "0 8px",
            fontSize: "20px",
            borderRadius: "8px",
            border: "1px solid #ddd",
          }}
          renderInput={(props) => <input {...props} />}
          containerStyle={{ justifyContent: "center", marginBottom: "24px" }}
        />

        <Box
          display="flex"
          justifyContent="space-between"
          mb={1}
          px={{ xs: 1, sm: 0 }}
        >
          <Typography
            color="text.secondary"
            fontSize={{ xs: "12px", sm: "14px" }}
          >
            <CountdownTimer
              key={timerKey}
              time="30"
              handleComplete={handleComplete}
            />
          </Typography>
          <Typography
            color="#B4B4B4"
            sx={{ cursor: "pointer", fontSize: { xs: "12px", sm: "14px" } }}
          >
            Didn't receive it?{" "}
            <span
              style={
                isDisplayResend ? { color: "#40A39B" } : { color: "#B4B4B4" }
              }
              onClick={handleResend}
            >
              Resend
            </span>
          </Typography>
        </Box>

        <Button
          fullWidth
          onClick={handleVerify}
          sx={{
            backgroundColor: "#40A39B",
            color: "white",
            borderRadius: "8px",
            py: { xs: 1.5, sm: 2 },
            fontSize: { xs: "14px", sm: "16px" },
          }}
          disabled={otp.length !== 4 || loading} // Disable if OTP is not fully entered
        >
          {loading ? "Verifying..." : "Verify OTP"}
        </Button>
      </Box>
    </Modal>
  );
};

export default OtpScreen;
