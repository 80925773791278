import React, { useState, useEffect } from "react";
import MyClassLayout from "../../my classes dashboard/MyClassLayout";
import HorizontalScheduler from "./HorizontalScheduler";
import img from "../../../assets/bookyoursessions/Vector.png";
import img1 from "../../../assets/bookyoursessions/vector1.png";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  OutlinedInput,
  Chip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSchedule,
  getSchedules,
  schedules,
} from "../../../Redux/Actions/tutorActions";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

const daysOfWeekOptions = [
  { value: 1, day: "Monday" },
  { value: 2, day: "Tuesday" },
  { value: 3, day: "Wednesday" },
  { value: 4, day: "Thursday" },
  { value: 5, day: "Friday" },
  { value: 6, day: "Saturday" },
  { value: 7, day: "Sunday" },
];

const monthDateOptions = Array.from({ length: 30 }, (_, i) => ({
  value: i + 1,
  label: (i + 1).toString(),
}));

const getValidationSchema = (recurrence_type) => {
  const baseSchema = {
    start_time: Yup.string().required("Start time is required"),
    end_time: Yup.string().required("End time is required"),
  };

  switch (recurrence_type) {
    case "Daily":
      return Yup.object(baseSchema);

    case "Weekly":
      return Yup.object({
        ...baseSchema,
        week_day: Yup.array().min(1, "At least one day is required"),
      });

    case "Monthly":
      return Yup.object({
        ...baseSchema,
        month_date: Yup.array().min(1, "At least one date is required"),
      });

    case "Once":
      return Yup.object({
        ...baseSchema,
        specific_date: Yup.string().required("Specific date is required"),
      });

    default:
      return Yup.object(baseSchema);
  }
};

const Sessions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [slots, setSlots] = useState([]);
  const [open, setOpen] = useState(false);
  const [recurrence_type, setrecurrence_type] = useState("Daily");
  const [availabilityMode, setAvailabilityMode] = useState(1);

  useEffect(() => {
    dispatch(getSchedules());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      start_time: "",
      end_time: "",
      recurrence_type: "Daily",
      month_date: [],
      week_day: [],
      specific_date: "",
    },
    validationSchema: getValidationSchema(recurrence_type),
    onSubmit: (values) => {
      handleAddSlot(values);
      setOpen(false);
      const payload = {
        start_time: moment(values.start_time, "HH:mm:ss").format("HH:mm:ss"),
        end_time: moment(values.end_time, "HH:mm:ss").format("HH:mm:ss"),
        recurrence_type: values.recurrence_type.toLowerCase(),
        month_dates: values.month_date ? values.month_date.map(String) : [],
        days_of_week: values.week_day ? values.week_day.map(String) : [],
        specific_date: values.specific_date
          ? moment(values.specific_date).toISOString()
          : moment(),
        availability_mode: availabilityMode,
      };

      dispatch(
        schedules(payload, () => {
          console.log("Hi");
        })
      );
      console.log(payload, "Form Data:", values);
    },
  });

  const handlerecurrence_typeChange = (e) => {
    setrecurrence_type(e.target.value);
    formik.setFieldValue("recurrence_type", e.target.value);
    formik.setErrors({});
  };

  const handleAddSlot = (newSlot) => {
    const formattedSlot = {
      ...newSlot,
      start_time: moment(newSlot.start_time, "HH:mm:ss").format("HH:mm:ss"),
      end_time: moment(newSlot.end_time, "HH:mm:ss").format("HH:mm:ss"),
    };
    setSlots((prevSlots) => [...prevSlots, "HH:mm:ss", formattedSlot]);
  };

  const getValue = (time) => {
    const daysOfWeek = {
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
      7: "Sunday",
    };

    switch (time?.recurrence_type) {
      case "daily":
        return `${time?.start_time} - ${time?.end_time}`;
        break;
      case "weekly":
        return time?.days_of_week?.map((day) => daysOfWeek[day]).join(", ");
        break;
      case "monthly":
        return `${time?.month_dates}`;
        break;
      case "once":
        return time?.specific_date;
        break;
      default:
        return moment();
    }
  };

  const handleRemoveSlot = (index) => {
    setSlots((prevSlots) => prevSlots.filter((_, i) => i !== index));
  };

  const handleModeSelect = (mode) => {
    setAvailabilityMode(mode);
  };

  const handleDeleteSlot = (id) => {
    dispatch(
      deleteSchedule(id, () => {
        getSchedulesData = getSchedulesData.filter(
          (schedule) => schedule.id !== id
        );
      })
    );
  };

  // const handleSaveClick = () => {
  //   cons,"HH:mm:ss"t formattedData = slots.map((slot) => ({
  //     start_time: dayjs(slot.start_time, "HH:mm","HH:mm:ss").format("HH:mm:ss"),
  //     end_time: dayjs(slot.end_time, "HH:mm","HH:mm:ss").format("HH:mm:ss"),
  //     recurrence_type: slot.recurrence_type.toLowerCase(),
  //     month_dates: slot.month_date ? slot.month_date.map(String) : [],
  //     days_of_week: slot.week_day ? slot.week_day.map(String) : [],
  //     specific_date: slot.specific_date
  //       ? dayjs(slot.specific_date).toISOString()
  //       : null,
  //     availability_mode: availabilityMode,
  //   }));

  //   console.log("Schedule Data:","HH:mm:ss", formattedData);
  //   navigate("/schedulestudent");
  // };
  const getSchedulesData = useSelector(
    (state) => state.getSchedulesReducer?.getSchedulesData?.rows
  );

  console.log(getSchedulesData, "getSchedulesData  ");

  return (
    <MyClassLayout>
      <div className="p-4">
        <h1 className="text-[24px] font-semibold mb-4">Schedule</h1>
        <HorizontalScheduler />

        <div className="flex flex-col mt-4">
          <span className="font-semibold mb-2">Select Mode</span>
          <div className="flex flex-row gap-2">
            <button
              onClick={() => handleModeSelect(1)}
              className={`px-4 py-2 rounded-3xl border ${
                availabilityMode === 1
                  ? "bg-[#40A39B] text-white"
                  : "hover:bg-[#40A39B] hover:text-white"
              }`}
            >
              Online
            </button>
            <button
              onClick={() => handleModeSelect(2)}
              className={`px-4 py-2 rounded-3xl border ${
                availabilityMode === 2
                  ? "bg-[#40A39B] text-white"
                  : "hover:bg-[#40A39B] hover:text-white"
              }`}
            >
              Face to Face
            </button>
            <button
              onClick={() => handleModeSelect(3)}
              className={`px-4 py-2 rounded-3xl border ${
                availabilityMode === 3
                  ? "bg-[#40A39B] text-white"
                  : "hover:bg-[#40A39B] hover:text-white"
              }`}
            >
              Open Session
            </button>
          </div>
        </div>

        <div className="flex flex-col gap-3 mt-4">
          <span className="font-semibold">
            Add your time for online availability
          </span>

          <button
            onClick={() => setOpen(true)}
            className="bg-[#40A39B] text-white w-[46px] h-[46px] rounded-[8px] flex items-center justify-center mt-3"
          >
            +
          </button>
          <div className="flex flex-col gap-3 mt-4">
            {slots.map(
              (slot, index) =>
                slot?.start_time && (
                  <div
                    key={index}
                    className="flex items-center bg-[#EBFFFC] justify-between p-[10px] rounded-[8px] w-[740px] h-[56px]"
                  >
                    <span className="flex items-center gap-2">
                      <img src={img} alt="time icon" />
                      <span>{`${slot.start_time} - ${slot.end_time}`}</span>
                    </span>
                    <button
                      onClick={() => handleRemoveSlot(index)}
                      className="px-2"
                    >
                      <img src={img1} alt="delete icon" />
                    </button>
                  </div>
                )
            )}

            {getSchedulesData?.map((time, index) => (
              <div
                key={index}
                className="flex justify-between items-center bg-[#EBFFFC] border rounded-[8px] w-[740px] h-[50px] p-4"
              >
                <span className="text-[14px] font-semibold">
                  {getValue(time)}
                </span>

                <div className="flex items-center gap-2">
                  <div>
                    {/* <EditIcon className="cursor-pointer" /> */}
                    <div
                      className="flex items-center justify-center px-4 py-1 rounded-[17px] border text-[14px] font-semibold"
                      style={{
                        background:
                          "linear-gradient(105.04deg, #C6FFC9 -25.33%, #D4EBFF 100%)",
                        width: "77px",
                        height: "26px",
                      }}
                    >
                      {time?.recurrence_type}
                    </div>
                    {/* <KeyboardArrowDownIcon className="cursor-pointer" /> */}
                  </div>
                  <button onClick={() => handleDeleteSlot(time?.id)}>
                    <img src={img1} alt="delete icon" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Dialog
  open={open}
  onClose={() => setOpen(false)}
  fullWidth
  maxWidth="sm" // Keeps the dialog compact
  sx={{
    "& .MuiDialog-paper": {
      borderRadius: "16px", // Rounded corners
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Adds depth
    },
  }}
>
  <DialogTitle
    sx={{
      fontSize: "1.5rem",
      fontWeight: "600",
      textAlign: "center",
      paddingBottom: 0,
    }}
  >
    Add New Slot
  </DialogTitle>
  <DialogContent sx={{ paddingTop: "1rem" }}>
    <form onSubmit={formik.handleSubmit}>
      <TextField
        select
        fullWidth
        label="Recurrence Type"
        name="recurrence_type"
        value={recurrence_type}
        onChange={handlerecurrence_typeChange}
        margin="normal"
        sx={{
          "& .MuiInputLabel-root": {
            fontSize: "0.9rem",
          },
        }}
      >
        {["Daily", "Weekly", "Monthly", "Once"].map((freq) => (
          <MenuItem key={freq} value={freq}>
            {freq}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Start Time"
        type="time"
        fullWidth
        margin="normal"
        {...formik.getFieldProps("start_time")}
        InputLabelProps={{ shrink: true }}
        sx={{
          "& .MuiInputLabel-root": {
            fontSize: "0.9rem",
          },
        }}
      />
      <TextField
        label="End Time"
        type="time"
        fullWidth
        margin="normal"
        {...formik.getFieldProps("end_time")}
        InputLabelProps={{ shrink: true }}
        sx={{
          "& .MuiInputLabel-root": {
            fontSize: "0.9rem",
          },
        }}
      />
      <Box sx={{ marginTop: "1rem" }}>
        {recurrence_type === "Monthly" && (
          <Select
            label="Month Dates"
            multiple
            fullWidth
            value={formik.values.month_date}
            onChange={(e) =>
              formik.setFieldValue("month_date", e.target.value)
            }
            input={<OutlinedInput label="Month Dates" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    sx={{
                      background: "#E8F5E9",
                      fontWeight: "500",
                    }}
                  />
                ))}
              </Box>
            )}
            sx={{ marginTop: "1rem" }}
          >
            {monthDateOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}

        {recurrence_type === "Weekly" && (
          <Select
            label="Week Days"
            multiple
            fullWidth
            value={formik.values.week_day}
            onChange={(e) =>
              formik.setFieldValue("week_day", e.target.value)
            }
            input={<OutlinedInput label="Week Days" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={
                      daysOfWeekOptions.find(
                        (day) => day.value === value
                      )?.day || value
                    }
                    sx={{
                      background: "#E3F2FD",
                      fontWeight: "500",
                    }}
                  />
                ))}
              </Box>
            )}
            sx={{ marginTop: "1rem" }}
          >
            {daysOfWeekOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.day}
              </MenuItem>
            ))}
          </Select>
        )}

        {recurrence_type === "Once" && (
          <TextField
            label="Specific Date"
            type="date"
            fullWidth
            margin="normal"
            {...formik.getFieldProps("specific_date")}
            InputLabelProps={{ shrink: true }}
          />
        )}
      </Box>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{
          marginTop: "2rem",
          padding: "12px 0",
          fontSize: "1rem",
          fontWeight: "600",
          textTransform: "none",
          borderRadius: "8px",
        }}
      >
        Save
      </Button>
    </form>
  </DialogContent>
</Dialog>


        {/* <div className="flex justify-end mt-6">
          <Button variant="contained" color="primary" onClick={handleSaveClick}>Save</Button>
        </div> */}
      </div>
    </MyClassLayout>
  );
};

export default Sessions;
