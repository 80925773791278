import React, { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FaqsPromo = () => {
  const [expanded, setExpanded] = useState(false);

  const questions = [
    {
      question: "What is the purpose of this platform?",
      answer:
        "Our platform connects students with tutors to enhance learning opportunities and personal growth.",
    },
    {
      question: "How do I sign up as a tutor?",
      answer:
        "Signing up as a tutor is easy. Simply click the 'Join as a Tutor' button on the homepage and fill out the registration form.",
    },
    {
      question: "What subjects are covered?",
      answer:
        "We offer a wide range of subjects including mathematics, science, arts, and recreational activities like music and painting.",
    },
    {
      question: "Is there a fee for students?",
      answer:
        "Yes, there is a small fee for students depending on the courses they choose. Details can be found on the pricing page.",
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        width: '90%',
        maxWidth: '1100px',
        margin: '0 auto',
        padding: '40px 0',
        textAlign: 'center',
      }}
    >
      <Typography
        sx={{
          fontFamily: "'Poppins', sans-serif",
          fontSize: '32px',
          fontWeight: 700,
          lineHeight: '36px',
          textAlign: 'center',
          marginBottom: '24px',
          // Adjust font size for smaller screens
          '@media (max-width:600px)': {
            fontSize: '28px',
            marginBottom: '20px',
          },
        }}
      >
        Frequently Asked Questions
      </Typography>

      {questions.map((item, index) => (
        <Box key={index}>
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
              boxShadow: 'none',
              '&:before': { display: 'none' },
              borderBottom: 'none',
              marginBottom: '0px',
            }}
          >
            <AccordionSummary
              expandIcon={
                <Box
                  sx={{
                    backgroundColor: expanded === `panel${index}` ? '#40A39B' : '#FFFFFF',
                    borderRadius: '50%',
                    width: '36px',
                    height: '36px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #E6E6E6',
                  }}
                >
                  <ExpandMoreIcon sx={{ color: expanded === `panel${index}` ? '#FFFFFF' : '#000' }} />
                </Box>
              }
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
              sx={{
                padding: '16px',
                '& .MuiAccordionSummary-content': {
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: '22px', // Slightly smaller for balanced look
                  fontWeight: 500,
                  lineHeight: '28px',
                  textAlign: 'left',
                  width: '95%',
                  '@media (max-width:600px)': {
                    fontSize: '20px',
                  },
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '22px',
                  fontFamily: "'Poppins', sans-serif",
                  '@media (max-width:600px)': {
                    fontSize: '20px',
                  },
                }}
              >
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '1.6',
                textAlign: 'left',
                padding: '16px',
                width: '95%',
                color: '#737373',
                fontFamily: "'Poppins', sans-serif",
                '@media (max-width:600px)': {
                  fontSize: '16px',
                },
              }}
            >
              {item.answer}
            </AccordionDetails>
          </Accordion>

          <Box
            sx={{
              height: '1px',
              backgroundColor: '#E6E6E6',
              width: '100%',
              margin: '16px 0',
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default FaqsPromo;
