import React from 'react'
import MainSection from './mainsection'
import AppDownload from "../download app/appdownload"
import Faqs from "../faq/faq"
import Promopackages from './Promopackages'
import PrivacyPolicyPromo from './PrivacyPolicyPromo'
import RatingsPromo from './RatingsPromo'
import FooterPromo from './FooterPromo'
import FaqsPromo from './FaqsPromo'

const Promolandingpage = () => {
  return (
    <div>
      <MainSection/>
      <Promopackages/>
  
      <AppDownload/>
      <RatingsPromo/>
      <PrivacyPolicyPromo/>
      {/* <Faqs/> */}
      <FaqsPromo/>
      <FooterPromo/>
    </div>
  )
}

export default Promolandingpage
