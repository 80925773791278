import Utils from "../../Utils";

const {
  sendMobileOtpSignup,
  expertise,
  getProfile,
  verifyMobileOtpSignup,
  signup,
  getTutorProfile,
  getStudentProfile,
  login,
  verifyMobileOtpLogin,
  sendMobileOtpLogin,
  forgetPassword,
  qualifications,
  grades,
  curriculums,
  sessions,
  getRateCard,
  faqs,
  subjects,
  getSchedules,
  getStudentDetails,
  getGradeClass,
  getCurriculums,
  getAcadmicTutorDetails,
  getRecreationalTutorDetails,
  getCourseDetails,
  getCourseCategories,
  getCourseDescription,
  getRecreationalDescription,
  getTutorDescription,
  getContent,
  getExpertise,
  profileStatus,
  topTutors,
  uploadDoc,
  packageTypes,
  classNschedules,
  getAvalability,
  getClasstype
} = Utils.actionName;
let initialState = [];

export const getRateCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case getRateCard:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const classNschedulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case classNschedules:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const uploadDocReducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadDoc:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const topTutorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case topTutors:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const profileStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case profileStatus:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getSchedulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case getSchedules:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const expertiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case expertise:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case getProfile:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getTutorProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case getTutorProfile:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const packageTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case packageTypes:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getStudentProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case getStudentProfile:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const gradesReducer = (state = initialState, action) => {
  switch (action.type) {
    case grades:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const qualificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case qualifications:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const curriculumsReducer = (state = initialState, action) => {
  switch (action.type) {
    case curriculums:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const subjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case subjects:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case sessions:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const verifyMobileOtpSignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case verifyMobileOtpSignup:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const verifyMobileOtpLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case verifyMobileOtpLogin:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case signup:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case login:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const sendMobileOtpLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case sendMobileOtpLogin:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const forgetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case forgetPassword:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const sendMobileOtpSignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case sendMobileOtpSignup:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const faqsReducer = (state = initialState, action) => {
  switch (action.type) {
    case faqs:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getStudentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getStudentDetails:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getCurriculumsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getCurriculums:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getGradeClassReducer = (state = initialState, action) => {
  switch (action.type) {
    case getGradeClass:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getAcadmicTutorDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getAcadmicTutorDetails:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getRecreationalTutorDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getRecreationalTutorDetails:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getCourseDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case getCourseDetails:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getCourseCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case getCourseCategories:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getCourseDescriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case getCourseDescription:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getTutorDescriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case getTutorDescription:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getRecreationalDescriptionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getRecreationalDescription:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case getContent:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getExpertiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case getExpertise:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getAvalabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case getAvalability:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const getClasstypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case getClasstype:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
