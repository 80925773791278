// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Menu,
//   MenuItem,
//   Typography,
//   Divider,
//   Avatar,
//   Button,
// } from "@mui/material";
// import { ExpandMore, ChevronRight } from "@mui/icons-material";
// import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate for routing
// import avatarImage from "../../assets/navbar/image.png";
// import { useDispatch, useSelector } from "react-redux";
// import { getProfile } from "../../Redux/Actions/index";

// const menuItems = [
//   "Manage Profile",
//   "Wallet",
//   "Terms of Services",
//   "FAQs",
//   "Rating & Feedbacks",
//   "Contact Us",
//   "Log Out",
// ];

// const menuRoutes = {
//   "Manage Profile": "/profileupdate",
//   Wallet: "/comingsoon",
//   "Terms of Services": "/terms_of_services",
//   FAQs: "/faq",
//   "Rating & Feedbacks": "/comingsoon",
//   "Contact Us": "/contact_us",
//   "Log Out": "/",
// };

// function MenuComponent() {
//   const navigate = useNavigate(); // Initialize the navigate hook
//   const location = useLocation();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const dispatch = useDispatch();
//   const getProfileData = useSelector(
//     (state) => state.getProfileReducer?.getProfileData
//   );

//   useEffect(() => {
//     dispatch(getProfile());
//   }, []);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleMenuItemClick = (item) => {
//     navigate(menuRoutes[item]);
//     handleClose();
//   };

//   return (
//     <div>
//       <Typography
//         variant="body1"
//         onClick={handleClick}
//         sx={{
//           fontWeight: 500,
//           fontSize: "16px",
//           lineHeight: "20px",
//           cursor: "pointer",
//           display: "flex",
//           alignItems: "center",
//           marginLeft: "8px",
//           color: "#737373",
//         }}
//       >
//         {getProfileData?.name}
//         <ExpandMore />
//       </Typography>
//       <Menu
//         id="simple-menu"
//         anchorEl={anchorEl}
//         keepMounted
//         open={Boolean(anchorEl)}
//         onClose={handleClose}
//         sx={{
//           "& .MuiPaper-root": {
//             width: { xs: "320px", sm: "360px" }, // Responsive width
//             height: "70%",
//             top: "96px !important",
//             right: "0 !important", // Align to the right side of the screen
//             background:
//               "linear-gradient(105.04deg, #C6FFC9 -25.33%, #D4EBFF 100%)",
//             padding: "16px",
//             borderRadius: "8px",
//           },
//         }}
//       >
//         <Box
//           sx={{
//             background: "rgba(255, 255, 255, 1)",
//             borderRadius: "8px",
//             padding: "16px",
//             display: "flex",
//             alignItems: "center",
//             marginBottom: "16px",
//           }}
//         >
//           <Avatar
//             src={avatarImage}
//             sx={{ width: 56, height: 56, marginRight: "12px" }}
//           />
//           <Box>
//             <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
//               {getProfileData?.name}
//             </Typography>
//             <Typography sx={{ fontSize: "14px", color: "#737373" }}>
//               {getProfileData?.email}
//             </Typography>
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 cursor: "pointer",
//                 marginTop: "8px",
//                 color: "#4A90E2",
//               }}
//               onClick={() => {
//                 navigate("/profile");
//                 handleClose();
//               }} // Navigate to profile
//             >
//               <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
//                 View Profile
//               </Typography>
//               <ChevronRight sx={{ marginLeft: "4px" }} />
//             </Box>
//           </Box>
//         </Box>
//         {menuItems.map((item,ind) => (
//           <Box key={ind}>
//           <MenuItem onClick={() => handleMenuItemClick(item)} sx={{
//             justifyContent: 'space-between',
//             padding: '12px 16px',
//             fontSize: '16px',
//             fontWeight: 500, 
//             color: '#333333',
//             gap: '8px',
//           }}>
//             {item}
//             <ChevronRight />
//           </MenuItem>
//           <Divider sx={{ backgroundColor: 'rgba(217, 217, 217, 1)', width: '80%', margin: 'auto' }} />
//         </Box>
//         ))}
//       </Menu>
//     </div>
//   );
// }

// export default MenuComponent;


import React, { useState, useEffect } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate for routing
import avatarImage from "../../assets/navbar/image.png";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../Redux/Actions/index";

const menuItems = [
  "Manage Profile",
  "Wallet",
  "Terms of Services",
  "FAQs",
  "Rating & Feedbacks",
  "Contact Us",
  "Log Out",
];

const menuRoutes = {
  "Manage Profile": "/profileupdate",
  Wallet: "/comingsoon",
  "Terms of Services": "/terms_of_services",
  FAQs: "/faq",
  "Rating & Feedbacks": "/comingsoon",
  "Contact Us": "/contact_us",
  "Log Out": "/",
};

function MenuComponent() {
  const navigate = useNavigate(); // Initialize the navigate hook
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const getProfileData = useSelector(
    (state) => state.getProfileReducer?.getProfileData
  );
  
  const [openDialog, setOpenDialog] = useState(false); // State for dialog

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    if (item === "Log Out") {
      setOpenDialog(true);  // Open the confirmation dialog
    } else {
      navigate(menuRoutes[item]);
      handleClose();
    }
  };

  const handleLogoutConfirm = () => {
    setOpenDialog(false); // Close the dialog
    navigate(menuRoutes["Log Out"]); // Navigate to the logout route
  };

  return (
    <div>
      <Typography
        variant="body1"
        onClick={handleClick}
        sx={{
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "20px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          marginLeft: "8px",
          color: "#737373",
        }}
      >
        {getProfileData?.name}
        <ExpandMore />
      </Typography>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: { xs: "320px", sm: "360px" }, // Responsive width
            height: "70%",
            top: "96px !important",
            right: "0 !important", // Align to the right side of the screen
            background:
              "linear-gradient(105.04deg, #C6FFC9 -25.33%, #D4EBFF 100%)",
            padding: "16px",
            borderRadius: "8px",
          },
        }}
      >
        <Box
          sx={{
            background: "rgba(255, 255, 255, 1)",
            borderRadius: "8px",
            padding: "16px",
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <Avatar
            src={avatarImage}
            sx={{ width: 56, height: 56, marginRight: "12px" }}
          />
          <Box>
            <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
              {getProfileData?.name}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#737373" }}>
              {getProfileData?.email}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "8px",
                color: "#4A90E2",
              }}
              onClick={() => {
                navigate("/profile");
                handleClose();
              }} // Navigate to profile
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                View Profile
              </Typography>
              <ChevronRight sx={{ marginLeft: "4px" }} />
            </Box>
          </Box>
        </Box>
        {menuItems.map((item, ind) => (
          <Box key={ind}>
            <MenuItem
              onClick={() => handleMenuItemClick(item)}
              sx={{
                justifyContent: "space-between",
                padding: "12px 16px",
                fontSize: "16px",
                fontWeight: 500,
                color: "#333333",
                gap: "8px",
              }}
            >
              {item}
              <ChevronRight />
            </MenuItem>
            <Divider
              sx={{
                backgroundColor: "rgba(217, 217, 217, 1)",
                width: "80%",
                margin: "auto",
              }}
            />
          </Box>
        ))}
      </Menu>

      {/* Confirmation Dialog */}
      <Dialog
  open={openDialog}
  onClose={() => setOpenDialog(false)}
  PaperProps={{
    sx: {
      borderRadius: "16px",
      padding: "24px",
      width: "100%",
      maxWidth: "400px",
    },
  }}
>
  <DialogContent
    sx={{
      textAlign: "center",
      padding: 0,
      marginBottom: "16px",
    }}
  >
    <DialogContentText
      sx={{
        fontSize: { xs: "20px", sm: "22px" },
        fontWeight: 700,
        color: "#333",
        mb: 2,
      }}
    >
      Confirm Logout
    </DialogContentText>
    <DialogContentText
      sx={{
        fontSize: "16px",
        color: "#555",
      }}
    >
      Are you sure you want to log out? You will need to sign in again to access your account.
    </DialogContentText>
  </DialogContent>
  <DialogActions
    sx={{
      justifyContent: "space-between",
      gap: 2,
      padding: "0 16px 8px",
    }}
  >
    <Button
      onClick={() => setOpenDialog(false)}
      sx={{
        backgroundColor: "#f5f5f5",
        color: "#333",
        borderRadius: "8px",
        padding: "12px 24px",
        textTransform: "none",
        fontWeight: 500,
        fontSize: "14px",
        border: "1px solid #ddd",
        "&:hover": {
          backgroundColor: "#e6e6e6",
        },
      }}
    >
      Cancel
    </Button>
    <Button
      onClick={handleLogoutConfirm}
      sx={{
        backgroundColor: "#40A39B",
        color: "white",
        borderRadius: "8px",
        padding: "12px 24px",
        textTransform: "none",
        fontWeight: 500,
        fontSize: "14px",
        boxShadow: "0px 4px 8px rgba(64, 163, 155, 0.3)",
        "&:hover": {
          backgroundColor: "#359488",
        },
      }}
    >
      Log Out
    </Button>
  </DialogActions>
</Dialog>


    </div>
  );
}

export default MenuComponent;
