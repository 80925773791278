import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import dataSciIcon from "../../assets/category/data sci.png"; // Fallback icon
import PromotionalBanner from "../common comps/promotionalbanner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getExpertise } from "../../Redux/Actions";

const Categories = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchKey, setSearchKey] = useState(""); // Search state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch expertise categories
  useEffect(() => {
    dispatch(getExpertise());
  }, [dispatch]);

  const categoriesData = useSelector(
    (state) => state.getExpertiseReducer?.getExpertiseData?.data?.rows || []
  );

  const handleSelect = (category) => {
    setSelectedCategory(category);
  };

  const handleContinue = () => {
    if (selectedCategory) {
      const url = window.location.pathname.split("/").pop();
      localStorage?.setItem("tutorListing", url);
      navigate("/tutorlisting");
    } else {
      alert("Please select a category before continuing.");
    }
  };

  // Filter categories based on search input
  const filteredCategories = categoriesData.filter((category) =>
    category.name.toLowerCase().includes(searchKey.toLowerCase())
  );

  return (
    <Box sx={{ padding: "20px", position: "relative" }}>
      <Box sx={{ maxWidth: "1145px", margin: "0 auto" }}>
        {/* Promotional Banner */}
        <PromotionalBanner
          sx={{
            width: "100%",
            backgroundColor: "#F5F5F5",
            borderRadius: "12px",
            padding: "16px",
            marginBottom: "25px",
          }}
        />

        {/* Title and description */}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            fontSize: "24px",
            color: "black",
            marginBottom: "8px",
            textAlign: "left",
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          Choose your Category
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: 400,
            fontSize: "18px",
            color: "#5F5F5F",
            marginBottom: "20px",
            textAlign: "left",
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          From Primary to University, Tailored Tutoring for Every Stage
        </Typography>

        {/* Search Input */}
        <TextField
          variant="outlined"
          placeholder="Search here..."
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)} // Update searchKey on input change
          sx={{
            width: { xs: "100%", sm: "100%", md: "1142px" },
            height: "44px",
            backgroundColor: "#F5F5F5",
            borderRadius: "6px",
            marginBottom: "25px",
            "& .MuiOutlinedInput-root": {
              height: "44px",
              padding: 0,
              "& fieldset": { border: "1px solid #E0E0E0" },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "black", ml: 1 }} />
              </InputAdornment>
            ),
            sx: {
              padding: "8px 12px",
              "& .MuiOutlinedInput-input": { padding: "10px 8px" },
            },
          }}
        />

        {/* Categories Grid */}
        {filteredCategories.length > 0 ? (
          <Grid container spacing={3}>
            {filteredCategories.map((category, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  onClick={() => handleSelect(category)}
                  sx={{
                    width: "100%",
                    height: "85px",
                    backgroundColor:
                      selectedCategory === category ? "#A8D0FF" : "#40A39B",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    padding: "10px",
                    gap: "18px",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  <Box
                    component="img"
                    src={
                      category?.image
                        ? `${process.env.REACT_APP_BASE_URL}${category.image}`
                        : dataSciIcon
                    }
                    sx={{ width: "32px", height: "32px" }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {category.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: "center", marginTop: 2 }}
          >
            No data found
          </Typography>
        )}
      </Box>

      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
          marginTop: "20px",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "164px",
            height: "48px",
            padding: "14px 16px",
            borderRadius: "8px",
            borderColor: "#40A39B",
            color: "#40A39B",
            fontFamily: "'Poppins', sans-serif",
          }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "164px",
            height: "48px",
            padding: "14px 16px",
            borderRadius: "8px",
            backgroundColor: "#40A39B",
            color: "#fff",
            marginRight: "75px",
            fontFamily: "'Poppins', sans-serif",
          }}
          onClick={handleContinue}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default Categories;
