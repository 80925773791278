// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAOm0s0UNMwYB58RvfRJqJMYC0xYtGKjPg",
  authDomain: "taleem-website.firebaseapp.com",
  projectId: "taleem-website",
  storageBucket: "taleem-website.appspot.com",
  messagingSenderId: "128323862743",
  appId: "1:128323862743:web:466dd097cf72d5e00df092",
  measurementId: "G-M436QMR5LM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
