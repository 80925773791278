import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import marketingIcon from "../../assets/category/marketing.png";
import { Link, useNavigate } from 'react-router-dom';
import { getCourseCategories } from '../../Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import PromotionalBanner from '../common comps/promotionalbanner';

const CourseCategories = ({ type = 2 }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    dispatch(getCourseCategories(type));
  }, [dispatch, type]);

  const categoriesData = useSelector(
    (state) => state.getCourseCategoriesReducer?.getCourseCategoriesData?.data?.rows || []
  );

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const handleContinueClick = () => {
    if (selectedCategory) {
      navigate(`/coursedetails`);
    } else {
      alert("Please select a category first.");
    }
  };

  return (
    <Box sx={{ padding: '20px', position: 'relative' }}>
      <Box sx={{ maxWidth: '1145px', margin: '0 auto' }}>
      
      

        <PromotionalBanner
          sx={{ width: '100%', backgroundColor: '#F5F5F5', borderRadius: '12px', padding: '16px', marginBottom: '25px' }}
        />

        <Typography variant="h5" sx={{ fontWeight: 700, fontSize: '24px', color: 'black', marginBottom: '8px', textAlign: 'left' ,   fontFamily:"'Poppins', sans-serif",}}>
          Choose your Course Category
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 400, fontSize: '18px', color: '#5F5F5F', marginBottom: '35px', textAlign: 'left',   fontFamily:"'Poppins', sans-serif", }}>
          From Primary to University, Tailored Tutoring for Every Stage
        </Typography>

        <Grid container spacing={3}>
          {categoriesData.map((category) => (
            <Grid item xs={12} sm={6} md={4} key={category.id}>
              <Box
                onClick={() => handleCategoryClick(category.id)}
                sx={{
                  width: '370px',
                  height: '85px',
                  backgroundColor: selectedCategory === category.id ? '#A8D0FF' : '#40A39B',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  gap: '18px',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
              >
                <Box
                  component="img"
                  src={category.image ? `${process.env.REACT_APP_BASE_URL}${category.image}` : marketingIcon}
                  alt={category.name}
                  sx={{ width: '32px', height: '32px' }}
                />
                <Typography variant="body1" sx={{ fontWeight: 500, fontSize: "18px", color: '#FFFFFF',   fontFamily:"'Poppins', sans-serif", }}>
                  {category.name}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', marginTop: "20px" }}>
        <Button
          variant="outlined"
          sx={{
            width: '164px',
            height: '48px',
            padding: '14px 16px',
            borderRadius: '8px',
            borderColor: '#40A39B',
            color: '#40A39B',
            fontFamily:"'Poppins', sans-serif",
          }}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            width: '164px',
            height: '48px',
            padding: '14px 16px',
            borderRadius: '8px',
            backgroundColor: '#40A39B',
            color: '#fff',
            marginRight: '75px',
            fontFamily:"'Poppins', sans-serif",
          }}
          onClick={handleContinueClick}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default CourseCategories;
