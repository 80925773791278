import Utils from "../../Utils";
import toast, { Toaster } from "react-hot-toast";

export const getSchedules = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getSchedules,
      ``,
      (resData) => {
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getSchedules,
            payload: {
              getSchedulesData: resData?.data,
            },
          });
          callback(resData);
        } else {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

export const profileStatus = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.profileStatus,
      ``,
      (resData) => {
        if (resData.status) {
          dispatch({
            type: Utils.actionName.profileStatus,
            payload: {
              profileStatusData: resData?.data,
            },
          });
          callback(resData);
        } else {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

export const getRateCard = (callback) => {
  return (dispatch) => {
    Utils.api.getApiCall(
      Utils.EndPoint.getRateCard,
      ``,
      (resData) => {
        if (resData.status) {
          dispatch({
            type: Utils.actionName.getRateCard,
            payload: {
              getRateCardData: resData?.data,
            },
          });
          callback(resData);
        } else {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

export const uploadDoc = (file, callback) => {
  return (dispatch) => {
    const formData = new FormData();
    formData.append("file", file); // Append the file to FormData

    Utils.api.postMultiApiCall(
      `${Utils.EndPoint.uploadDoc}`,
      formData, // Pass the FormData object
      (responseData) => {
        console.log(responseData?.data, "Upload Response");
        if (responseData?.status) {
       

          toast.success(
            responseData?.message || "File uploaded successfully!",
            {
              position: "top-center",
            }
          );
        console.log(responseData?.data, "File uploaded successfully1111");

          callback(responseData?.data); // Invoke the callback with the response data
          dispatch({
            type: Utils.actionName.uploadDoc,
            payload: { file, Response: responseData?.data }, // Update with server response
          });
        } else {
          toast.error(responseData?.message || "Something Went Wrong!", {
            position: "top-center",
          });
        }
      },
      (error) => {
        // toast.error(error?.message || "Failed to upload file", {
        //   position: "top-center",
        // });
      }
    );
  };
};

// export const uploadDoc = (value, callback) => {
//   return (dispatch) => {
//     console.log(value,"Data is here")
//     Utils.api.postMultiApiCall(
//       `${Utils.EndPoint.uploadDoc}`,
//       // `{file:${value}}`,
//       value,
//       (responseData) => {
//         console.log(responseData, "responseDataresponseData");
//         dispatch({
//           type: Utils.actionName.uploadDoc,
//           payload: { ...value, Response: responseData?.data },
//         });
//         if (responseData?.status == true) {
//           toast.success(`${responseData?.message}`, {
//             position: "top-center",
//           });
//           callback(responseData);
//         }
//       },
//       (error) => {
//         toast.error(`${error?.payload?.APICODERESULT}`, {
//           position: "top-center",
//         });
//       },

//     );
//   };
// };
export const schedules = (value, callback) => {
  return (dispatch) => {
    Utils.api.postTokenApiCall(
      `${Utils.EndPoint.schedules}`,
      value,
      (responseData) => {
        console.log(responseData, "responseDataresponseData");
        dispatch({
          type: Utils.actionName.schedules,
          payload: { ...value, Response: responseData?.data },
        });
        if (responseData?.status == true) {
          toast.success(`${responseData?.message}`, {
            position: "top-center",
          });
          callback(responseData);
        }
      },
      (error) => {
        toast.error(`${error?.payload?.APICODERESULT}`, {
          position: "top-center",
        });
      }
    );
  };
};

export const addRateCard = (value, callback) => {
  return (dispatch) => {
    Utils.api.postTokenApiCall(
      `${Utils.EndPoint.addRateCard}`,
      value,
      (responseData) => {
        dispatch({
          type: Utils.actionName.addRateCard,
          payload: { ...value, Response: responseData?.data },
        });
        if (responseData?.status == true) {
          toast.success(`${responseData?.message}`, {
            position: "top-center",
          });
          callback(responseData);
        } else {
          toast.error(`${responseData?.message}`, {
            position: "top-center",
          });
        }
      },
      (error) => {
        toast.error(`${error?.payload?.APICODERESULT}`, {
          position: "top-center",
        });
      }
    );
  };
};

export const deleteSchedule = (value, callback) => {
  return (dispatch) => {
    Utils.api.deleteApiCall(
      `${Utils.EndPoint.schedules}/${value}`,
      "",
      (responseData) => {
        console.log(responseData, "ResponseData");
        // dispatch({
        //   type: Utils.actionName.addRateCard,
        //   payload: { ...value, Response: responseData?.data },
        // });
        if (responseData?.status == true) {
          toast.success(`${responseData?.message}`, {
            position: "top-center",
          });
          callback(responseData);
        }
      },
      (error) => {
        toast.error(`${error?.message}`, {
          position: "top-center",
        });
      }
    );
  };
};
